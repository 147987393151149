import api from '../axios'

const adminSupportApi = {
  // Get tickets list
  getTickets(query) {
    return api.get('/admin/support/tickets/' + query)
  },
  // // Get user info
  // getUser(id) {
  //   return api.get(`/admin/users/${id}`)
  // },
  // // Save user
  // saveUser(id, data) {
  //   return api.patch(`/admin/users/${id}`, data)
  // },
}

export default adminSupportApi
