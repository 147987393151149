<template>
  <Card>
    <template #title>
      {{ user.name }}
      <small v-if="user.status == 0" class="text-red-500"
        >({{ $t('inactive') }})</small
      >
    </template>
    <template #content>
      <EditUserForm v-if="!loading" :user="user" />
    </template>
  </Card>
</template>

<script>
import { ref, onBeforeMount } from 'vue'
import adminUsersApi from '@/api/admin/users'
import router from '@/router'
import EditUserForm from '@/components/admin/users/EditUserForm.vue'

export default {
  components: { EditUserForm },
  setup() {
    const user = ref({})
    const loading = ref(true)

    // Get user info
    onBeforeMount(() => {
      adminUsersApi
        .getUser(router.currentRoute.value.params.id)
        .then((response) => {
          user.value = response.data
          loading.value = false
        })
    })

    return { user, loading }
  },
}
</script>
