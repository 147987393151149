<template>
  <h3 class="text-xl font-bold mb-5">Чат</h3>
  <div
    class="mb-3 grid border rounded bg-slate-50 p-5 pb-0 content-end"
    style="height: 30vh"
  >
    <!-- Message -->
    <div v-for="(message, key) in messages" :key="key" class="flex gap-4 mb-5">
      <div class="flex-none content-center">
        <Avatar
          :label="message.sender.name.charAt(0).toUpperCase()"
          size="large"
          shape="circle"
          class="avatar-green"
        />
      </div>
      <div class="flex-auto rounded-xl border p-5 bg-white">
        {{ message.text }}
      </div>
      <div class="flex-none pt-2">
        <span class="text-gray-500">{{ message.createdAt }}</span>
      </div>
    </div>
  </div>

  <!-- Send message -->
  <div class="flex flex-row gap-4">
    <div class="basis-11/12">
      <Textarea v-model="form.message" class="w-full" rows="1" />
    </div>
    <div class="basis-1/12">
      <Button
        type="button"
        :label="$t('send')"
        :loading="submitting"
        @click="send"
        icon="pi pi-send"
        class="w-full"
        :disabled="!canSend"
      />
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue'
import designApi from '@/api/design'
import { useToast } from 'primevue/usetoast'
import i18n from '@/i18n'

export default {
  props: ['id'],
  setup(props) {
    const form = ref({})
    const submitting = ref(false)

    // Toast
    const toast = useToast()

    // Get messages
    const canSend = computed(() => {
      return form.value.message
    })
    const messages = ref([])
    const getMessages = () => {
      designApi
        .getChatMessages(props.id)
        .then((response) => {
          messages.value = response.data
        })
        .catch((error) => {
          toast.add({
            severity: 'error',
            summary: i18n.global.t('error'),
            detail: error.response.data.message,
            life: 5000,
          })
        })
    }
    getMessages()

    // Send message
    const send = () => {
      submitting.value = true
      designApi
        .sendChatMessage(props.id, form.value)
        .then((response) => {
          submitting.value = false
          form.value.message = null
          toast.add({
            severity: 'success',
            detail: response.data.message,
            life: 3000,
          })
          getMessages()
        })
        .catch((error) => {
          submitting.value = false
          toast.add({
            severity: 'error',
            summary: i18n.global.t('error'),
            detail: error.response.data.message,
            life: 5000,
          })
        })
    }

    // Set messages seen
    setTimeout(() => {
      designApi
        .setMessagesSeen(props.id)
        .then(() => {
          getMessages()
        })
        .catch((error) => {
          toast.add({
            severity: 'error',
            summary: i18n.global.t('error'),
            detail: error.response.data.message,
            life: 5000,
          })
        })
    }, 3000)

    return { messages, form, canSend, submitting, send }
  },
}
</script>
