import api from './axios'

const companyApi = {
  // Get user's companies list
  getUserCompanies() {
    return api.get('/user/companies')
  },
  // Get company info
  getCompanyInfo(id) {
    return api.get(`/user/companies/${id}`)
  },
  // Add company
  addCompany(data) {
    return api.post('/user/companies', data)
  },
  // Update company
  updateCompany(id, data) {
    return api.patch(`/user/companies/${id}`, data)
  },
  // Set default
  setDefault(id) {
    return api.post(`/user/companies/${id}/set_default`)
  },
}

export default companyApi
