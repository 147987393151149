<template>
  <div class="flex">
    <SideBar />
    <main class="flex-1">
      <TopBar />
      <div class="p-10">
        <router-view />
      </div>
    </main>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
// import { useToast } from 'primevue/usetoast'
import SideBar from '@/components/nav/SideBar.vue'
import TopBar from '@/components/nav/TopBar.vue'

export default defineComponent({
  components: { SideBar, TopBar },
  setup() {
    // const toast = useToast()
    // const showSuccess = () => {
    //   toast.add({
    //     severity: 'success',
    //     summary: 'Success',
    //     detail: text,
    //     life: 5000,
    //   })
    // }
  },
})
</script>
