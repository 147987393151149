<template>
  <TabMenu :model="supportTabs" :exact="false" class="mb-5 -mt-5">
    <template #item="{ item }">
      <router-link :to="item.to" class="p-menuitem-link">
        <span class="p-menuitem-text">{{ item.label }}</span>
      </router-link>
    </template>
  </TabMenu>
  <Card>
    <template #title>
      {{ $t('support') }}
    </template>
    <template #content>
      <DataTable
        v-if="!loading"
        :value="tickets"
        :paginator="true"
        :rows="20"
        dataKey="id"
        :rowHover="true"
        stripedRows
        selectionMode="single"
        @rowSelect="onTicketSelect"
      >
        <Column field="id" header="ID"></Column>
        <Column :header="$t('status')">
          <template #body="{ data }">
            <span v-if="data.status == 0" class="badge-info">{{
              $t(`supportStatus${data.status}`)
            }}</span>
            <span v-if="data.status == 1" class="badge-success">{{
              $t(`supportStatus${data.status}`)
            }}</span>
            <span v-if="data.status == 2" class="badge-danger">{{
              $t(`supportStatus${data.status}`)
            }}</span>
          </template>
        </Column>
        <Column field="message" :header="$t('message')"></Column>
        <Column field="createdAt" :header="$t('date')"></Column>
        <Column>
          <template #body="{ data }">
            <router-link :to="`/admin/users/${data.client.id}`">
              <span>{{ data.client.name }}</span>
            </router-link>
          </template>
        </Column>
      </DataTable>
    </template>
  </Card>
</template>

<script>
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import { ref, onMounted, watch } from 'vue'
import i18n from '@/i18n'
import TabMenu from 'primevue/tabmenu'
import { useRoute } from 'vue-router'
import adminSupportApi from '@/api/admin/support'
import router from '@/router'

export default {
  components: { DataTable, Column, TabMenu },
  setup() {
    const loading = ref(true)
    const tickets = ref([])
    // Route
    const route = useRoute()

    // Get tickets
    onMounted(() => {
      getTickets()
    })

    const getTickets = () => {
      const query =
        route.name == 'admin_support'
          ? '?f[status][in][]=0&f[status][in][]=1'
          : '?f[status][in][]=3'

      adminSupportApi.getTickets(query).then((response) => {
        tickets.value = response.data.items
        loading.value = false
      })
    }

    watch(
      () => route.name,
      () => {
        getTickets()
      }
    )

    // Tabs
    const supportTabs = [
      {
        label: i18n.global.t('activeTickets'),
        to: '/admin/support',
        status: 1,
      },
      {
        label: i18n.global.t('closedTickets'),
        to: '/admin/support/closed',
        status: 0,
      },
    ]

    const onTicketSelect = (event) => {
      router.push(`/support/tickets/${event.data.id}`)
    }

    return { loading, tickets, supportTabs, onTicketSelect }
  },
}
</script>
