<template>
  <div class="conditions">
    <h3>Общие положения</h3>
    <p>
      Платформа ИЗБИЗ берет на себя задачу модерации и контроля за правильным и
      целевым использованием сайта https://iz-biz.com, ограждая пользователей
      сайта от ненужной информации (спама).
    </p>
    <p>Ответственность за опубликованную информацию</p>
    <p>
      Платформа ИЗБИЗ не несет ответственности за законность, правильность и
      полноту информации, представленной пользователями настоящего сайта.
    </p>
    <p>
      Информация, размещенная на сайте пользователями, должна соответствовать
      законам РФ и стран, на территории которых пользователи осуществляют свою
      деятельность.
    </p>
    <p>
      Ответственность за размещенную на сайте информацию несёт лицо,
      разместившее эту информацию. Платформа ИЗБИЗ является площадкой для
      размещения информации и в случае обращения органов правопорядка РФ или
      других стран удаляет запрещенную информацию.
    </p>
    <h3>Правила размещения информации</h3>
    <p>Информация, размещенная на сайте https://iz-biz.com, должна:</p>
    <ul>
      <li>соответствовать законам РФ;</li>
      <li>быть грамотно оформлена на русском языке;</li>
      <li>
        содержать полные и достоверные сведения о хозяйственной деятельности
        пользователей;
      </li>
      <li>содержать полные и достоверные сведения о предлагаемых товарах;</li>
      <li>
        при размещении данных через формы ввода сайта https://iz-biz.com
        вводимая информация должна соответствовать названию и описанию
        соответствующей страницы и элемента для ввода.
      </li>
    </ul>
    <h3>Запрещается размещать:</h3>
    <ul>
      <li>
        информацию о товарах и услугах, не соответствующих законодательству РФ;
      </li>
      <li>незаконно полученную частную или конфиденциальную информацию;</li>
      <li>
        информацию, нарушающую авторские и исключительные права третьих лиц;
      </li>
      <li>информацию с порнографическим и непристойным содержанием;</li>
      <li>информацию, связанную с насилием;</li>
      <li>спам, вредоносные программы и вирусы (в том числе ссылки);</li>
      <li>информацию о заработке в интернете.</li>
    </ul>
    <h3>Правила использования информации</h3>
    <p>
      В случае копирования любой информации с сайта https://iz-biz.com на другие
      сайты обязательно сохранение прямой ссылки на оригинальную страницу
      https://iz-biz.com; ссылка должна располагаться непосредственно после
      скопированной информации; ссылка должна быть доступна всем пользователям,
      в том числе неавторизованным на вашем сайте.
    </p>
    <p>
      Сбор информации с сайта https://iz-biz.com какими-либо программами
      (парсерами) запрещен.
    </p>
    <h3>Политика конфиденциальности</h3>
    <p>
      Платформа ИЗБИЗ заинтересовано защищать вашу конфиденциальность. В разделе
      "Политика конфиденциальности" объясняется процесс обработки ваших данных и
      приводятся ответы на вопросы, связанные с использованием персональных
      данных.
    </p>
    <h3>Накопленная информация</h3>
    <p>
      Платформа ИЗБИЗ собирает подробную информацию о вашей компании при
      регистрации и из открытых источников, а также информацию, которую мы
      получаем, когда вы пользуетесь нашими услугами. Мы собираем всю информацию
      о запросах, в которые вы будете вовлечены. Время от времени мы можем
      отслеживать посещаемые вами страницы, время, проведенное на сайте, и
      другие используемые конфигурации. Для этих целей сайт использует cookies,
      они могут быть недоступны на вашем браузере.
    </p>
    <h3>Использование вашей информации и ваших предпочтений</h3>
    <p>
      После того как вы зарегистрируетесь на нашем сайте Платформа ИЗБИЗ, вы
      получите доступ к персональной странице пользователя.
    </p>
    <h3>Конфиденциальная информация</h3>
    <p>К конфиденциальной информации мы относим:</p>
    <ul>
      <li>пароли;</li>
      <li>персональные данные;</li>
      <li>историю посещения страниц;</li>
      <li>информацию о взаиморасчетах.</li>
    </ul>
    <p>
      Мы никогда не раскрываем вашу конфиденциальную информацию публично или
      третьим лицам в ходе переговоров, за исключением случаев, когда мы будем
      обязаны раскрыть информацию в соответствии с законодательством Российской
      Федерации.
    </p>
    <p>
      Политика в отношении обработки персональных данных доступна по ссылке.
    </p>
    <h3>Открытая информация</h3>
    <p>
      Наша площадка служит для улучшения и автоматизации бизнес-коммуникаций,
      поэтому большая часть информации является открытой и доступной для
      публичного просмотра:
    </p>
    <ul>
      <li>информация о вашей компании (название, реквизиты, контакты);</li>
      <li>информация о ваших заказах, тендерах;</li>
      <li>информация о ваших предложениях на заказы, тендеры.</li>
    </ul>
    <h3>Правила поведения на сайте</h3>
    <p>При общении на сайте запрещается:</p>
    <ul>
      <li>
        Публиковать сообщения, нарушающие действующее законодательство РФ.
      </li>
      <li>Публиковать заведомо ложную информацию.</li>
      <li>Публиковать информацию, не имеющую отношения к теме страницы.</li>
      <li>
        Использовать нецензурные и/или грубые выражения (в том числе в
        замаскированной форме).
      </li>
      <li>
        Оскорблять кого-либо в прямой или косвенной форме, проявлять неуважение
        к пользователям и модераторам.
      </li>
    </ul>
  </div>
</template>
