<template>
  <div>
    <Breadcrumb :home="home" :model="items" class="mb-4 -mt-6" />
    <Card class="2xl:w-4/5">
      <template #title>
        {{ $t('addTenderTitle') }}
      </template>
      <template #content>
        <TenderForm v-if="user.status !== 0" />
        <div v-else>
          {{ $t('cantCreateTenderUnactiveUser') }}
        </div>
      </template>
    </Card>
  </div>
</template>

<script>
import TenderForm from '@/components/tenders/TenderForm.vue'
import Breadcrumb from 'primevue/breadcrumb'
import { ref } from 'vue'
import i18n from '@/i18n'
import { useAuthStore } from '@/store/auth.store'

export default {
  components: { TenderForm, Breadcrumb },
  setup() {
    const authStore = useAuthStore()
    const { user } = authStore

    const home = ref({
      icon: 'pi pi-home',
      to: '/',
    })
    const items = ref([
      { label: i18n.global.t('nav.tenders'), to: '/tenders' },
      { label: i18n.global.t('addTenderTitle'), disabled: true },
    ])

    return { home, items, user }
  },
}
</script>
