<template>
  <Button
    @click="showMenu = !showMenu"
    icon="pi pi-bars"
    class="visible md:hidden absolute z-50 top-5 left-5 p-button-sm"
  />
  <aside
    v-if="showMenu"
    class="w-80 h-screen absolute md:sticky top-0 bg-white drop-shadow-xl z-10"
  >
    <div class="pl-10 pt-7 mb-7">
      <router-link to="/">
        <img
          src="@/assets/img/logo.svg"
          alt="IZBIZ"
          class="logo hidden md:block"
        />
      </router-link>
    </div>
    <Menu :model="items" class="w-full text-xl" :exact="false" />
  </aside>
</template>

<script>
import { ref } from 'vue'
import Menu from 'primevue/menu'
import menuItems from '../../configs/sidenav'
import { useAuthStore } from '@/store/auth.store'

export default {
  components: { Menu },
  setup() {
    const authStore = useAuthStore()
    // Check navbar roles
    const menu = menuItems.filter((item) => {
      return authStore.hasRole(item.roles)
    })
    const items = ref(menu)
    const showMenu = ref(true)

    return { items, showMenu }
  },
}
</script>

<style lang="scss" scoped>
.logo {
  width: 80%;
}

::v-deep(.p-menu) {
  @apply w-full border-none px-7;
  .p-menuitem {
    @apply my-3;
  }
  .p-menuitem-content:hover {
    @apply rounded-md;
  }
  .router-link-active {
    @apply bg-gray-100 rounded-md;
  }
}
</style>
