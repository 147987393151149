<template>
  <div class="md:hidden">
    <router-link to="/">
      <img src="@/assets/img/logo.svg" alt="IzBiz" class="mt-3 ml-20 w-72" />
    </router-link>
  </div>
  <div>
    <Toolbar class="ml-5">
      <template #start>
        <!-- <span class="p-input-icon-left">
          <i class="pi pi-search" />
          <InputText
            type="text"
            class="p-inputtext-sm"
            v-model="search"
            :placeholder="t('search')"
          />
        </span> -->
        <span v-if="user.default_company">{{ user.default_company.name }}</span>
      </template>
      <template #end>
        <router-link to="/notifications" class="mr-2 cursor-pointer">
          <i
            v-if="user.hasNotifications"
            class="pi pi-bell p-text-secondary text-sm"
            style="font-size: 1.2rem"
            v-badge.danger
          ></i>
          <i
            v-else
            class="pi pi-bell p-text-secondary text-sm"
            style="font-size: 1.2rem"
          ></i>
        </router-link>
        <Avatar
          :label="user.name.charAt(0).toUpperCase()"
          class="ml-5 mr-2"
          shape="circle"
        />
        <Button
          type="button"
          @click="toggle"
          aria-haspopup="true"
          aria-controls="user_menu"
          class="p-button-secondary p-button-text p-button-sm btn-user"
          >{{ user.name }}</Button
        >
        <Menu id="user_menu" ref="menu" :model="userMenuItems" :popup="true" />
      </template>
    </Toolbar>
    <div v-if="user.status == 0" class="px-10">
      <Message
        severity="error"
        icon="pi pi-exclamation-triangle"
        :closable="false"
        class="mb-0"
        >{{ $t('messageUserStatusInactive') }}</Message
      >
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import Toolbar from 'primevue/toolbar'
import Menu from 'primevue/menu'
import Avatar from 'primevue/avatar'
import i18n from '@/i18n'
import { useAuthStore } from '@/store/auth.store'
import router from '@/router'
import Message from 'primevue/message'

export default {
  components: { Toolbar, Menu, Avatar, Message },
  setup() {
    const store = useAuthStore()
    const { user } = store
    const menu = ref()
    const t = i18n.global.t
    const search = ref(null)
    const userMenuItems = ref([
      {
        label: t('profile'),
        icon: 'pi pi-user',
        command: () => {
          router.push('/profile')
        },
      },
      {
        label: t('logout'),
        icon: 'pi pi-sign-out',
        command: () => {
          store.logout()
        },
      },
    ])

    const toggle = (event) => {
      menu.value.toggle(event)
    }

    return { menu, userMenuItems, toggle, t, user, search }
  },
}
</script>

<style lang="scss" scoped>
::v-deep(.p-toolbar) {
  @apply bg-transparent border-l-0 border-t-0 border-r-0 border-b rounded-none;
}
.btn-user:focus {
  box-shadow: none !important;
}
</style>
