<template>
  <Toast position="bottom-right" />
  <form @submit.prevent="save">
    <!-- Description -->
    <div class="mb-5">
      <label class="block mb-2" for="description">
        {{ $t('description') }}
      </label>
      <Textarea v-model="form.text" rows="5" id="description" class="w-full" />
    </div>
    <!-- Files -->
    <div class="mb-5">
      <div>
        <label class="block mb-2" for="files">{{ $t('files') }}</label>
        <FileUpload
          name="files[]"
          customUpload
          @select="onUpload"
          :multiple="true"
          :maxFileSize="1000000"
          :showUploadButton="false"
          :showCancelButton="false"
          :chooseLabel="$t('selectFiles')"
        >
          <template #empty>
            <p>Drag and drop files to here to upload.</p>
          </template>
        </FileUpload>
      </div>
    </div>
    <!-- Save -->
    <div>
      <Button
        type="button"
        :label="$t('addDesignRequest')"
        :loading="submitting"
        @click="save"
        icon="pi pi-save"
      />
    </div>
  </form>
</template>

<script>
import { ref } from 'vue'
import designApi from '@/api/design'
import { useToast } from 'primevue/usetoast'
import i18n from '@/i18n'
import Textarea from 'primevue/textarea'
import FileUpload from 'primevue/fileupload'
import router from '@/router'

export default {
  components: { Textarea, FileUpload },
  setup() {
    const form = ref({})
    const errors = ref([])
    const submitting = ref(false)

    // Toast
    const toast = useToast()

    // Upload files
    const onUpload = (e) => {
      form.value.files = e.files
    }

    // Save
    const save = () => {
      submitting.value = true
      errors.value = []

      designApi
        .addDesignRequest(form.value)
        .then((response) => {
          toast.add({
            severity: 'success',
            summary: i18n.global.t('requestSent'),
            detail: i18n.global.t('requstSuccessfullySent'),
            life: 3000,
          })

          form.value = {
            text: null,
            files: [],
          }

          submitting.value = false
          router.push(`/design/${response.data.id}`)
        })
        .catch((error) => {
          if (error.response.data.errors) {
            errors.value = error.response.data.errors
          }
          toast.add({
            severity: 'error',
            summary: i18n.global.t('error'),
            detail: error.response.data.message,
            life: 5000,
          })
        })
    }

    return { form, errors, submitting, onUpload, save }
  },
}
</script>
