<template>
  <Toast position="bottom-right" />
  <h3 v-if="bids.length > 0" class="text-2xl mb-5">
    {{ $t('bidsList') }}
  </h3>
  <div
    v-for="(bid, i) in bids"
    :key="i"
    class="border-gray-300 rounded-lg border p-5 mb-5"
  >
    <!-- User -->
    <div class="grid grid-cols-2 mb-7">
      <div>
        <Avatar
          :label="bid.user.name.charAt(0).toUpperCase()"
          class="mr-5 float-left"
          size="xlarge"
          shape="circle"
        />
        <div class="float-left mr-10">
          <a href="#">{{ bid.user.name }}</a>
          <div class="text-gray-400 mt-1">{{ bid.createdAt }}</div>
        </div>
      </div>
    </div>
    <!-- Sum -->
    <div class="text-xl mb-7">
      <span class="mr-10"
        >{{ $t('request_sum') }}: <b>{{ bid.sum.toLocaleString() }}</b
        >&nbsp;&#8381;</span
      >
    </div>
    <!-- Approve -->
    <div v-if="bid.status == 0">
      <div v-if="owner.id == user.id">
        <Button
          :label="$t('selectBuyer')"
          icon="pi pi-check"
          @click="approveBid(bid)"
          class="mr-5 p-button-outlined"
        />
      </div>
    </div>
    <!-- Status -->
    <div>
      <InlineMessage
        v-if="bid.status == 0 && owner.id != user.id"
        severity="info"
        >{{ $t('requestPending') }}</InlineMessage
      >
      <InlineMessage v-if="bid.status == 1" severity="success">{{
        $t('bidApproved')
      }}</InlineMessage>
    </div>
  </div>

  <!-- Confirm dialog -->
  <ConfirmDialog group="approve" :draggable="false" class="w-1/3">
    <template #message="slotProps">
      <div>
        <div class="mb-5">
          <span>{{ slotProps.message.message }}</span>
        </div>
        <div>
          <b class="text-xl">{{ currentBid.user.name }}</b>
        </div>
      </div>
    </template>
  </ConfirmDialog>
</template>

<script>
import { ref, defineComponent } from 'vue'
import { useToast } from 'primevue/usetoast'
import { useConfirm } from 'primevue/useconfirm'
import tenderApi from '@/api/tender'
import Avatar from 'primevue/avatar'
import { useAuthStore } from '@/store/auth.store'
import i18n from '@/i18n'

export default defineComponent({
  components: { Avatar },
  props: ['bids', 'owner'],
  setup(props, context) {
    // User
    const authStore = useAuthStore()
    const { user } = authStore
    // Toast
    const toast = useToast()
    // Confirm
    const confirm = useConfirm()
    const currentBid = ref(null)

    // Approve bid
    const approveBid = (bid) => {
      currentBid.value = bid
      confirm.require({
        group: 'approve',
        header: i18n.global.t('buyerConfirmation'),
        message: i18n.global.t('confirmBuyerApprove'),
        accept: () => {
          tenderApi
            .approveBid(bid.id)
            .then((response) => {
              toast.add({
                severity: 'success',
                summary: i18n.global.t('buyerConfirmation'),
                detail: response.data.message,
                life: 3000,
              })
              context.emit('bidApproved')
            })
            .catch((error) => {
              toast.add({
                severity: 'error',
                summary: i18n.global.t('error'),
                detail: error.response.data.message,
                life: 5000,
              })
            })

          currentBid.value = null
        },
        reject: () => {
          currentBid.value = null
        },
      })
    }

    return { user, currentBid, approveBid }
  },
})
</script>
