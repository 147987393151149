import api from './axios'

const userSettingsApi = {
  getSettings() {
    return api.get('/user/settings')
  },
  setSettings(data) {
    return api.post('/user/settings', data)
  },
}

export default userSettingsApi
