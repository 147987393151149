<template>
  <!-- Tender status -->
  <div>
    <ul class="tender-states mt-7 mb-9">
      <li
        :class="{
          active: tenderStore.tenderInfo.status >= 1,
          'font-bold': tenderStore.tenderInfo.status == 1,
        }"
      >
        {{ $t('created') }}
      </li>
      <li
        :class="{
          active: tenderStore.tenderInfo.status >= 2,
          'font-bold': tenderStore.tenderInfo.status == 2,
        }"
      >
        <span v-if="tenderStore.tenderInfo.owner.type == 1">{{
          $t('chosenManufactor')
        }}</span>
        <span v-else>{{ $t('chosenBuyer') }}</span>
      </li>
      <li
        :class="{
          active: tenderStore.tenderInfo.status >= 3,
          'font-bold': tenderStore.tenderInfo.status == 3,
        }"
      >
        {{ $t('signingContrcat') }}
      </li>
      <li
        :class="{
          active: tenderStore.tenderInfo.status >= 4,
          'font-bold': tenderStore.tenderInfo.status == 4,
        }"
      >
        {{ $t('inProduction') }}
      </li>
      <li
        :class="{
          active: tenderStore.tenderInfo.status >= 5,
          'font-bold': tenderStore.tenderInfo.status == 5,
        }"
      >
        {{ $t('shipping') }}
      </li>
      <li
        :class="{
          active: tenderStore.tenderInfo.status >= 6,
          'font-bold': tenderStore.tenderInfo.status == 6,
        }"
      >
        {{ $t('waitingDelivery') }}
      </li>
      <li
        :class="{
          active: tenderStore.tenderInfo.status >= 7,
          'font-bold': tenderStore.tenderInfo.status == 7,
        }"
      >
        {{ $t('inDelivery') }}
      </li>
      <li
        :class="{
          active: tenderStore.tenderInfo.status >= 8,
          'font-bold': tenderStore.tenderInfo.status == 8,
        }"
      >
        {{ $t('shipped') }}
      </li>
    </ul>
  </div>
  <!-- Tender info -->
  <Card>
    <template #content>
      <div class="grid grid-cols-2">
        <div class="mb-7 text-xl">
          <div class="mr-10 mb-2">
            {{ $t('customer') }}:
            <a href="#">{{ tenderStore.tenderInfo.owner.name }}</a>
          </div>
          <div v-if="tenderStore.tenderInfo.company" class="mb-2">
            {{ $t('companyTitle') }}:
            <a href="#">{{ tenderStore.tenderInfo.company.name }}</a>
          </div>
          <div v-if="tenderStore.tenderInfo.executor" class="font-bold">
            <span v-if="tenderStore.tenderInfo.owner.type == 1"
              >{{ $t('executor') }}:
            </span>
            <span v-else>{{ $t('buyer') }}: </span>
            <a href="#">{{ tenderStore.tenderInfo.executor.name }}</a>
          </div>
        </div>
        <div
          v-if="user.id == tenderStore.tenderInfo.owner.id || user.is_admin"
          class="text-right"
        >
          <!-- Change status -->
          <ModalActivate
            :visible="showActivationModal"
            :status="newStatus"
            :id="tenderStore.tenderInfo.id"
            @statusUpdated="statusUpdated"
            @hide="showActivationModal = false"
            :style="{ width: '30vw' }"
          />
          <Button
            v-if="tenderStore.tenderInfo.status == 0"
            :label="$t('activate')"
            severity="success"
            icon="pi pi-replay"
            outlined
            class="p-button-success p-button-outlined mr-3"
            @click="setStatus(1)"
          />
          <Button
            v-if="tenderStore.tenderInfo.status == 1"
            :label="$t('offTender')"
            severity="secondary"
            icon="pi pi-eye-slash"
            outlined
            class="p-button-secondary p-button-outlined mr-3"
            @click="setStatus(0)"
          />
          <!-- Delete tender -->
          <ModalDeleteTender
            :visible="showDeleteModal"
            :id="tenderStore.tenderInfo.id"
            @hide="showDeleteModal = false"
            @deleted="tenderDeleted"
            :style="{ width: '30vw' }"
          />
          <Button
            :label="$t('delete')"
            severity="danger"
            icon="pi pi-trash"
            outlined
            class="p-button-danger p-button-outlined"
            @click="showDeleteModal = true"
          />
        </div>
      </div>
      <hr class="mb-6" />
      <div class="grid grid-cols-4 mb-7">
        <div>
          <span class="mb-3 inline-block">{{ $t('date_beg') }}</span>
          <p class="text-2xl">
            <i class="pi pi-calendar text-2xl"></i>
            {{ tenderStore.tenderInfo.date_beg }}
          </p>
        </div>
        <div>
          <span class="mb-3 inline-block">{{ $t('date_end') }}</span>
          <p class="text-2xl">
            <i class="pi pi-calendar text-2xl"></i>
            {{ tenderStore.tenderInfo.date_end }}
          </p>
        </div>
      </div>
      <div class="mb-7">
        <div>
          <span class="mb-3 inline-block">{{ $t('category') }}</span>
          <p class="text-xl">
            <span v-for="(name, i) in tenderStore.categoryPath" :key="i">
              <span
                v-if="i != tenderStore.categoryPath.length - 1"
                class="text-gray-400 hover:text-gray-500 cursor-pointer"
              >
                {{ name }}
              </span>
              <span
                v-else
                class="text-blue-600 hover:text-blue-500 cursor-pointer"
              >
                {{ name }} </span
              >&nbsp;<span v-if="i != tenderStore.categoryPath.length - 1">
                ->
              </span>
            </span>
          </p>
        </div>
      </div>
      <div v-if="tenderStore.tenderInfo.owner.type == 2" class="mb-7">
        <div class="grid grid-cols-4">
          <div>
            <span class="mb-3 inline-block">{{ $t('start_price') }}</span>
            <p class="text-xl">
              <span class="font-bold text-2xl"
                >{{ tenderStore.tenderInfo.min_price }}&nbsp;&#8381;</span
              >
            </p>
          </div>
          <div class="col-span-3">
            <span class="mb-3 inline-block">{{ $t('buy_price') }}</span>
            <p class="text-xl">
              <span class="font-bold text-2xl"
                >{{ tenderStore.tenderInfo.buy_price }}&nbsp;&#8381;</span
              >
            </p>
          </div>
        </div>
      </div>
      <div class="mb-7">
        <div class="grid grid-cols-4">
          <div>
            <span class="mb-3 inline-block">{{ $t('quantity') }}</span>
            <p class="text-xl">
              <span class="font-bold">{{
                tenderStore.tenderInfo.quantity
              }}</span>
            </p>
          </div>
          <div class="col-span-3">
            <span class="mb-3 inline-block">{{ $t('brand') }}</span>
            <p class="text-xl">
              &laquo;<span class="font-bold">{{
                tenderStore.tenderInfo.brand
              }}</span
              >&raquo;
            </p>
          </div>
        </div>
      </div>
      <div v-if="tenderStore.tenderInfo.files.length" class="mb-7">
        <span class="mb-3 inline-block">{{ $t('files') }}</span>
        <ul class="text-xl tender-files">
          <li
            v-for="(file, i) in tenderStore.tenderInfo.files"
            :key="i"
            @click="download.downloadFile(file.id, file.name)"
            :style="
              file.isImage
                ? {
                    backgroundImage:
                      'url(' + baseUrl + '/upload' + file.path + ')',
                  }
                : ''
            "
          >
            <span class="file-name">{{ file.name }}</span>
            <span class="ext">{{ file.ext }}</span>
          </li>
        </ul>
      </div>
      <div v-if="tenderStore.hasPack" class="mb-7">
        <span class="mb-3 inline-block">{{ $t('pack') }}</span>
        <p class="text-2xl">
          <i class="pi pi-box text-2xl"></i>
          {{ tenderStore.tenderInfo.options.pack['length'] }}&times;{{
            tenderStore.tenderInfo.options.pack['width']
          }}&times;{{ tenderStore.tenderInfo.options.pack['height'] }}
        </p>
      </div>
      <div v-if="tenderStore.tenderInfo.note" class="grid grid-cols-4 mb-10">
        <div class="col-span-3">
          <span class="mb-3 inline-block">{{ $t('comment') }}</span>
          <p class="text-lg italic">{{ tenderStore.tenderInfo.note }}</p>
        </div>
      </div>
      <!-- Tender requests (Tender from CUSTOMER) -->
      <div v-if="tenderStore.tenderInfo.owner.type == 1">
        <!-- Request form -->
        <TenderRequest
          v-if="tenderStore.tenderInfo.canSendRequest"
          :tender="tenderStore.tenderInfo"
          @tenderRequestSent="getTender"
        />
        <!-- Requests list -->
        <TenderRequestsList
          :requests="tenderStore.tenderInfo.requests"
          :owner="tenderStore.tenderInfo.owner"
          @requestApproved="getTender"
          @requestDenied="getTender"
          @executeApproved="getTender"
        />
      </div>
      <!-- Tender bids (Tender from PRODUCER) -->
      <div v-if="tenderStore.tenderInfo.owner.type == 2">
        <!-- Tender bid form -->
        <TenderBidForm
          v-if="tenderStore.tenderInfo.canSendBid"
          :tender="tenderStore.tenderInfo"
          @tenderBidSent="getTender"
        />
        <!-- Tender bids list -->
        <TenderBidsList
          :bids="tenderStore.tenderInfo.bids"
          :owner="tenderStore.tenderInfo.owner"
          @bidApproved="getTender"
        />
      </div>
    </template>
  </Card>
</template>

<script>
import { ref } from 'vue'
import { useTenderStore } from '@/store/tender.store'
import { useAuthStore } from '@/store/auth.store'
import useDownload from '@/composables/download'
import ModalActivate from '@/components/tenders/modal/ModalActivateTender.vue'
import ModalDeleteTender from '@/components/tenders/modal/ModalDeleteTender.vue'
import TenderRequest from '@/components/tenders/TenderRequest.vue'
import TenderRequestsList from '@/components/tenders/TenderRequestsList.vue'
import TenderBidForm from '@/components/tenders/TenderBidForm.vue'
import TenderBidsList from '@/components/tenders/TenderBidsList.vue'
import router from '@/router'

export default {
  components: {
    ModalActivate,
    ModalDeleteTender,
    TenderRequest,
    TenderRequestsList,
    TenderBidForm,
    TenderBidsList,
  },
  setup() {
    // User
    const authStore = useAuthStore()
    const { user } = authStore
    // Tender
    const tenderStore = useTenderStore()
    const newStatus = ref()
    const getTender = () => {
      tenderStore.fetchTender(tenderStore.tenderInfo.id)
    }
    // Download file
    const download = useDownload()
    // Activate
    const showActivationModal = ref(false)
    // Status
    const setStatus = (status) => {
      newStatus.value = status
      showActivationModal.value = true
    }
    const statusUpdated = () => {
      showActivationModal.value = false
      getTender()
    }
    // Delete
    const showDeleteModal = ref(false)
    const tenderDeleted = () => {
      router.push('/tenders')
    }
    const baseUrl = process.env.VUE_APP_API_URL

    return {
      tenderStore,
      user,
      newStatus,
      download,
      showActivationModal,
      showDeleteModal,
      baseUrl,
      setStatus,
      statusUpdated,
      tenderDeleted,
      getTender,
    }
  },
}
</script>
