<template>
  <Card>
    <template #title>
      {{ user.name }}
      <small v-if="user.status == 0" class="text-red-500"
        >({{ $t('inactive') }})</small
      >
    </template>
    <template #content>
      <div class="mb-5 text-lg">
        {{ $t('accountType') }}: <b>{{ $t('userType' + user.type) }}</b>
      </div>
      <ProfileForm />
    </template>
  </Card>
</template>

<script>
import ProfileForm from '@/components/profile/ProfileForm.vue'
import { useAuthStore } from '@/store/auth.store'

export default {
  components: { ProfileForm },
  setup() {
    const store = useAuthStore()
    const user = store.user

    return { user }
  },
}
</script>
