import fileApi from '@/api/file'

export default function useDownload() {
  const downloadFile = (id, fileName) => {
    fileApi.downloadFile(id).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')

      link.href = url
      link.setAttribute('download', fileName)
      document.body.appendChild(link)
      link.click()
    })
  }

  return { downloadFile }
}
