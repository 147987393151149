import { createRouter, createWebHistory } from 'vue-router'
import { useAuthStore } from '@/store/auth.store'
import { useDictionaryStore } from '@/store/dictionary.store'

import Layout from '@/views/Layout'
// Auth
import Auth from '@/views/auth/Auth'
import Login from '@/views/auth/Login'
import Register from '@/views/auth/Register'
// Main
import Design from '@/views/design/Index'
import DesignAdd from '@/views/design/Add'
import DesignRequest from '@/views/design/DesignRequest'
import Support from '@/views/support/Index'
import Ticket from '@/views/support/Ticket'
import Settings from '@/views/settings/Index'
// Tenders
import TendersIndex from '@/views/tenders/Index'
import TenderAdd from '@/views/tenders/Add'
import TendersList from '@/views/tenders/List'
import Tender from '@/views/tenders/Tender'
import MyTenders from '@/views/tenders/MyTenders'
import Requests from '@/views/tenders/Requests'
import TenderDocs from '@/components/tenders/TenderDocs'
import TenderInfo from '@/components/tenders/TenderInfo'
import TenderChat from '@/components/tenders/TenderChat'
// Profile
import ProfileIndex from '@/views/profile/Index'
import Profile from '@/views/profile/Profile'
import MyCompanies from '@/views/profile/MyCompanies'
import CompaniesList from '@/views/profile/CompaniesList'
import AddCompany from '@/views/profile/AddCompany'
import EditCompany from '@/views/profile/EditCompany'
// Notifications
import Notifications from '@/views/notifications/Notifications'
// Admin
import Admin from '@/views/admin/Index'
import AdminUsers from '@/views/admin/users/Index'
import AdminEditUser from '@/views/admin/users/Edit'
import AdminRegions from '@/views/admin/regions/Index'
import AdminCategories from '@/views/admin/categories/Index'
import AdminSupport from '@/views/admin/support/Index'

const routes = [
  {
    path: '/',
    name: 'home',
    component: Layout,
    meta: {
      requiresAuth: true,
    },
    redirect: () => {
      return { path: '/tenders' }
    },
    beforeEnter: (to, from, next) => {
      const auth = useAuthStore()
      const dictionary = useDictionaryStore()
      auth
        .fetchAuthUser()
        .then(() => {
          // Check roles
          if (to.meta.roles && !auth.hasRole(to.meta.roles)) {
            next('/')
          }
          // Dictionary
          dictionary
            .fetchCategories()
            .then(() => {
              next()
            })
            .catch(() => {
              next()
            })
        })
        .catch(() => {
          auth.logout()
          next('login')
        })
    },
    children: [
      {
        path: '/tenders_index',
        name: 'tenders_index',
        component: TendersIndex,
        meta: {
          requiresAuth: true,
        },
        children: [
          {
            path: '/tenders',
            name: 'tenders',
            component: TendersList,
          },
          {
            path: '/tenders/add',
            name: 'tenders_add',
            component: TenderAdd,
          },
          {
            path: '/tenders/my',
            name: 'tenders_my',
            component: MyTenders,
          },
          {
            path: '/tenders/my/closed',
            name: 'tenders_my_closed',
            component: MyTenders,
          },
          {
            path: '/tenders/requests',
            name: 'tenders_requests',
            component: Requests,
          },
          {
            path: '/tenders_id',
            name: 'tenders_id',
            component: Tender,
            children: [
              {
                path: '/tenders/:id',
                name: 'tender_info',
                component: TenderInfo,
              },
              {
                path: '/tenders/:id/docs',
                name: 'tender_docs',
                component: TenderDocs,
              },
              {
                path: '/tenders/:id/chat',
                name: 'tender_chat',
                component: TenderChat,
              },
            ],
          },
        ],
      },
      {
        path: '/design',
        name: 'design',
        component: Design,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/design/add',
        name: 'design_add',
        component: DesignAdd,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/design/:id',
        name: 'design_info',
        component: DesignRequest,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/support',
        name: 'support',
        component: Support,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/support/tickets/:id',
        name: 'support_ticket',
        component: Ticket,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/settings',
        name: 'settings',
        component: Settings,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/notifications',
        name: 'notifications',
        component: Notifications,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/profile_index',
        name: 'profile_index',
        component: ProfileIndex,
        meta: {
          requiresAuth: true,
        },
        children: [
          {
            path: '/profile',
            name: 'profile',
            component: Profile,
          },
          {
            path: '/profile/companies_index',
            name: 'profile_companies',
            component: MyCompanies,
            children: [
              {
                path: '/profile/companies',
                name: 'profile_companies_list',
                component: CompaniesList,
              },
              {
                path: '/profile/companies/add',
                name: 'profile_companies_add',
                component: AddCompany,
              },
              {
                path: '/profile/companies/:id',
                name: 'profile_companies_edit',
                component: EditCompany,
              },
            ],
          },
        ],
      },
      {
        path: '/admin',
        name: 'admin',
        component: Admin,
        meta: {
          requiresAuth: true,
          roles: ['ROLE_ADMIN'],
        },
        children: [
          {
            path: '/admin/users',
            name: 'admin_users',
            component: AdminUsers,
          },
          {
            path: '/admin/users/:id',
            name: 'admin_edit_user',
            component: AdminEditUser,
          },
          {
            path: '/admin/regions',
            name: 'admin_regions',
            component: AdminRegions,
          },
          {
            path: '/admin/categories',
            name: 'admin_categories',
            component: AdminCategories,
          },
          {
            path: '/admin/support',
            name: 'admin_support',
            component: AdminSupport,
          },
          {
            path: '/admin/support/closed',
            name: 'admin_support_closed',
            component: AdminSupport,
          },
        ],
      },
    ],
  },
  {
    path: '/auth',
    name: 'auth',
    component: Auth,
    children: [
      {
        path: '/login',
        name: 'login',
        component: Login,
      },
      {
        path: '/register',
        name: 'register',
        component: Register,
      },
    ],
  },

  // {
  //   path: '/restore_password',
  //   name: 'restore_password',
  //   component: RestorePassword,
  // },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
  // },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router
