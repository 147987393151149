<template>
  <DataTable
    v-if="!loading"
    :value="tickets"
    dataKey="id"
    :rowHover="true"
    stripedRows
    :loading="loading"
    selectionMode="single"
    v-model:selection="selectedTicket"
    @rowSelect="onTicketSelect"
    :metaKeySelection="false"
  >
    <Column field="status" class="w-0">
      <template #body="{ data }">
        <Tag v-if="data.status == 0" severity="info" value="Info">Новое</Tag>
        <Tag v-if="data.status == 1" severity="success" value="Success"
          >В работе</Tag
        >
        <Tag v-if="data.status == 2" severity="danger" value="Danger"
          >Закрыто</Tag
        >
      </template>
    </Column>
    <Column field="id" header="ID">
      <template #body="{ data }">
        <router-link :to="`/support/tickets/${data.id}`"
          ><b>{{ data.id }}</b>
        </router-link>
      </template>
    </Column>
    <Column field="message" :header="$t('message')"></Column>
    <Column field="createdAt" :header="$t('date')"></Column>
  </DataTable>
</template>

<script>
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import { ref } from 'vue'
import router from '@/router'
import Tag from 'primevue/tag'

export default {
  props: ['tickets'],
  components: { DataTable, Column, Tag },
  setup() {
    const loading = ref(false)
    const selectedTicket = ref()

    const onTicketSelect = (event) => {
      router.push(`/support/tickets/${event.data.id}`)
    }
    return { loading, selectedTicket, onTicketSelect }
  },
}
</script>
