<template>
  <Card>
    <template #title>
      {{ company.name }}
    </template>
    <template #content>
      <CompanyForm v-if="!loading" :company="company" />
    </template>
  </Card>
</template>

<script>
import { ref, onBeforeMount } from 'vue'
import companyApi from '@/api/company'
import router from '@/router'
import CompanyForm from '@/components/profile/CompanyForm.vue'

export default {
  components: { CompanyForm },
  setup() {
    const company = ref({})
    const loading = ref(true)

    // Get company info
    onBeforeMount(() => {
      companyApi
        .getCompanyInfo(router.currentRoute.value.params.id)
        .then((response) => {
          company.value = response.data
          // To delete
          company.value.addressesToDelete = []
          company.value.requisitesToDelete = []
          loading.value = false
        })
    })

    return { company, loading }
  },
}
</script>
