<template>
  <div>
    <Card>
      <template #title>
        {{ $t('nav.help') }}
      </template>
      <template #content>
        <div class="text-xl mb-3">
          <p class="pb-3"><i class="pi pi-phone"></i>&nbsp;+7 985 126-83-63</p>
          <p class="pb-5">
            <i class="pi pi-home"></i>&nbsp;Москва, ул. 4 Магистральная д. 11,
            офис 14
          </p>
          <div>
            <div class="pb-3">
              <label for="message">{{ $t('message') }}</label>
            </div>
            <Textarea
              v-model="form.message"
              class="w-2/3"
              rows="5"
              id="message"
            />
          </div>
        </div>
        <div>
          <Button
            type="button"
            :label="$t('send')"
            :loading="submitting"
            @click="send"
            icon="pi pi-send"
          />
        </div>
        <div v-if="tickets.length > 0">
          <hr class="my-5" />
          <h3 class="text-xl font-bold mb-5">{{ $t('myTickets') }}</h3>
          <TicketsList :tickets="tickets" />
        </div>
      </template>
    </Card>
  </div>
</template>

<script>
// import UnderConstruction from '@/components/blocks/UnderConstruction.vue'
import { ref } from 'vue'
import { useToast } from 'primevue/usetoast'
import TicketsList from '@/components/support/TicketsList.vue'
import supportApi from '@/api/support'
import i18n from '@/i18n'
import router from '@/router'

export default {
  components: { TicketsList },
  setup() {
    const form = ref({})
    const submitting = ref(false)
    const tickets = ref([])
    const errors = ref([])

    // Toast
    const toast = useToast()

    // Tickets list
    supportApi.getTickets().then((response) => {
      tickets.value = response.data
    })

    // Add ticket
    const send = () => {
      submitting.value = true
      supportApi
        .addTicket(form.value)
        .then((response) => {
          toast.add({
            severity: 'success',
            summary: i18n.global.t('ticketSent'),
            life: 3000,
          })
          form.value = {
            message: null,
          }

          submitting.value = false
          router.push(`/support/tickets/${response.data.id}`)
        })
        .catch((error) => {
          if (error.response.data.errors) {
            errors.value = error.response.data.errors
          }
          toast.add({
            severity: 'error',
            summary: i18n.global.t('error'),
            detail: error.response.data.message,
            life: 5000,
          })
        })
    }

    return { form, submitting, tickets, send }
  },
}
</script>
