<template>
  <Card>
    <template #title>
      <div class="grid grid-cols-2">
        <div>
          <div class="mb-2">{{ $t('designRequest') }} #{{ id }}</div>
          <InlineMessage v-if="designRequest.status == 0" severity="info">{{
            $t('designRequestNew')
          }}</InlineMessage>
          <InlineMessage v-if="designRequest.status == 1" severity="success">{{
            $t('designRequestActive')
          }}</InlineMessage>
          <InlineMessage
            v-if="designRequest.status == 2"
            severity="error"
            class="bg-red-50"
            >{{ $t('designRequestFinished') }}</InlineMessage
          >
          <InlineMessage v-if="designRequest.status == 3" severity="success">{{
            $t('designRequestCancelled')
          }}</InlineMessage>
        </div>
        <div class="text-right font-normal text-lg">
          <i class="pi pi-calendar"></i>&nbsp;{{ designRequest.createdAt }}
        </div>
      </div>
    </template>
    <template #content>
      <div class="mb-5">
        <i class="pi pi-user"></i>&nbsp;<b>{{ designRequest.client.name }}</b>
        (ID: {{ designRequest.client.id }})
      </div>
      <div class="p-5 mb-7 rounded-xl border bg-slate-50">
        {{ designRequest.text }}
      </div>
      <div v-if="designRequest.files.length" class="mb-5">
        <span class="mb-3 inline-block">{{ $t('files') }}</span>
        <ul class="text-xl tender-files">
          <li
            v-for="(file, i) in designRequest.files"
            :key="i"
            @click="download.downloadFile(file.id, file.name)"
            :style="
              file.isImage
                ? {
                    backgroundImage:
                      'url(' + baseUrl + '/upload' + file.path + ')',
                  }
                : ''
            "
          >
            <span class="file-name">{{ file.name }}</span>
            <span class="ext">{{ file.ext }}</span>
          </li>
        </ul>
      </div>
      <div>
        <DesignChat :id="id" />
      </div>
    </template>
  </Card>
</template>

<script>
import router from '@/router'
import designApi from '@/api/design'
import { ref } from 'vue'
import useDownload from '@/composables/download'
import DesignChat from '@/components/design/DesignChat.vue'

export default {
  components: { DesignChat },
  setup() {
    const id = router.currentRoute.value.params.id
    const designRequest = ref({
      client: {},
      files: [],
    })
    // Download file
    const download = useDownload()
    const baseUrl = process.env.VUE_APP_API_URL

    designApi.designInfo(id).then((response) => {
      designRequest.value = response.data
    })

    return { id, designRequest, download, baseUrl }
  },
}
</script>
