<template>
  <TabMenu :model="profileMenu" :exact="false" class="mb-5 -mt-5" />
  <router-view></router-view>
</template>

<script>
import { ref } from 'vue'
import TabMenu from 'primevue/tabmenu'
import i18n from '@/i18n'
import { useAuthStore } from '@/store/auth.store'

export default {
  components: { TabMenu },
  setup() {
    const store = useAuthStore()
    const user = store.user
    const profileMenu = ref([
      {
        label: i18n.global.t('profile'),
        icon: 'pi pi-fw pi-user',
        to: '/profile',
      },
    ])

    if (user.form_type == 1) {
      profileMenu.value.push({
        label: i18n.global.t('myCompanies'),
        icon: 'pi pi-fw pi-briefcase',
        to: '/profile/companies',
      })
    }

    return { user, profileMenu }
  },
}
</script>

<!-- <style lang="scss" scoped>
::v-deep(.p-tabmenu) {
  .p-tabmenu-nav {
    @apply bg-none;
  }
}
</style> -->
