import { defineStore } from 'pinia'
import authApi from '@/api/auth'
import router from '@/router'
// import api from '@/api/axios'

export const useAuthStore = defineStore('auth', {
  state: () => ({
    user: null,
  }),

  actions: {
    // Login
    async login(username, password, rememberMe) {
      const response = await authApi.login(username, password, rememberMe)
      // Set user
      this.setUser(response.data.user, response.data.token)
      // Redirect
      router.push('/')
    },
    // User info
    async fetchAuthUser() {
      const response = await authApi.getAuthUser()
      // Set store user
      this.user = response.data
      // Set admin
      this.user.is_admin = this.isAdmin()
    },
    // Logout
    logout() {
      this.user = null
      localStorage.removeItem('token')
      router.push('/login')
    },
    // Register
    async register(data) {
      const response = await authApi.register(data)
      // Set user
      this.setUser(response.data.user, response.data.token)
      // Redirect
      router.push('/')
    },
    // Set current user
    setUser(user, token) {
      // Set store user
      this.user = user
      // Set token
      localStorage.setItem('token', token)
      // Redirect
    },
    // Check if user has at least one role in array
    hasRole(roles) {
      let hasRole = false
      roles.forEach((role) => {
        if (!hasRole) {
          hasRole = this.user.roles.includes(role)
        }
      })
      return hasRole
    },
    // Update user profile
    async updateUser(data) {
      const response = await authApi.updateUser(data)
      // Set store user
      this.user = response.data
    },
    setDefaultCompany(company) {
      this.user.default_company = company
    },
    isAdmin() {
      return this.hasRole(['ROLE_ADMIN'])
    },
    // TODO: Can (has permission)
    // can(permission) {
    // },
  },
})
