import { defineStore } from 'pinia'
import categoryApi from '@/api/category'

export const useDictionaryStore = defineStore('dictionary', {
  state: () => ({
    categories: [],
    tree: [],
    preparedTree: [],
    preparedtreeIds: {},
    preparedTreeKeys: {},
  }),

  actions: {
    // Get categories
    async fetchCategories() {
      const response = await categoryApi.getCategories()
      this.setCategories(response.data)
    },
    // Set categories
    setCategories(data) {
      this.categories = data
      this.fillCategoriesTree()
    },
    // Tree
    fillCategoriesTree() {
      const arrayToTree = (arr, parentId = null) =>
        arr
          .filter((item) => item.parent_id === parentId)
          .map((children) => ({
            ...children,
            children: arrayToTree(arr, children.id),
          }))

      this.tree = arrayToTree(this.categories)

      // Prepare tree for tree component
      const prepareTree = (tree, k = null) => {
        tree.forEach((category, index) => {
          let key = k == null ? index.toString() : k + '-' + index.toString()

          category.key = key
          category.label = category.name
          this.preparedtreeIds[key] = category.id
          this.preparedTreeKeys[category.id] = key

          if (category.children.length > 0) {
            category.children = prepareTree(category.children, key)
          }
        })

        return tree
      }

      this.preparedTree = prepareTree(this.tree)
    },
    // // Set selected
    // getSelectedKeys(categoryIds) {
    //   let selected = {}

    //   // selectedKey.value = {
    //   //   0: { checked: false, partialChecked: true },
    //   //   '0-2-0': { checked: true, partialChecked: false },
    //   //   '0-2': { checked: false, partialChecked: true },
    //   // }

    //   categoryIds.forEach((id) => {
    //     let checked = false
    //     let partialChcked = false

    //     selected[this.preparedTreeKeys[id]] = {
    //       checked: checked,
    //       partialChecked: checked,
    //     }
    //   })

    //   return selected
    // },
  },
})
