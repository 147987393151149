<template>
  <div class="m-auto w-full overflow-y-auto bg-white">
    <form @submit.prevent="register">
      <div class="mb-3 pb-3 pl-1">
        <label class="block mb-2" for="name">{{ $t('accountType') }}</label>
        <span class="field-radiobutton mr-5">
          <RadioButton
            inputId="type1"
            name="type"
            value="1"
            v-model="form.type"
            class="align-middle mr-2"
          />
          <label for="type1">{{ $t('customer') }}</label>
        </span>
        <span
          v-if="form.form_type == 1 || !form.form_type"
          class="field-radiobutton"
        >
          <RadioButton
            inputId="type2"
            name="type"
            value="2"
            v-model="form.type"
            class="align-middle mr-2"
          />
          <label for="type2">{{ $t('producer') }}</label>
        </span>
        <div v-if="errors.type">
          <small v-for="(error, i) in errors.type" :key="i" class="p-error">{{
            error
          }}</small>
        </div>
      </div>
      <div class="mb-3 pb-4 pl-1 border-b">
        <!-- <label class="block mb-2" for="name">{{ $t('jurForm') }}</label> -->
        <span class="field-radiobutton mr-5">
          <RadioButton
            inputId="form_type1"
            name="form_type"
            value="1"
            v-model="form.form_type"
            class="align-middle mr-2"
          />
          <label for="form_type1">{{ $t('juridical') }}</label>
        </span>
        <span v-if="form.type == 1" class="field-radiobutton">
          <RadioButton
            inputId="form_type2"
            name="form_type"
            value="2"
            v-model="form.form_type"
            class="align-middle mr-2"
          />
          <label for="form_type2">{{ $t('physical') }}</label>
        </span>
        <div v-if="errors.form_type">
          <small
            v-for="(error, i) in errors.form_type"
            :key="i"
            class="p-error"
            >{{ error }}</small
          >
        </div>
      </div>
      <div class="mb-3">
        <label class="block mb-2" for="name">{{ $t('country') }}</label>
        <Dropdown
          v-model="country"
          :options="countries"
          optionLabel="name"
          optionValue="code"
          :placeholder="$t('selectCountry')"
          class="w-full"
          :class="{ 'p-invalid': errors.region && !country }"
          :editable="true"
        />
        <div v-if="errors.region && !country">
          <small class="p-error">{{ $t('needToSelectCountry') }}</small>
        </div>
      </div>
      <div v-if="country && currentCountry" class="mb-3">
        <label class="block mb-2" for="name">{{ $t('region') }}</label>
        <Dropdown
          v-model="form.region"
          :options="currentCountry.regions"
          optionLabel="name"
          optionValue="id"
          :placeholder="$t('selectRegion')"
          class="w-full"
          :class="{ 'p-invalid': errors.region }"
          :editable="true"
        />
        <div v-if="errors.region">
          <small class="p-error">{{ $t('needToSelectRegion') }}</small>
        </div>
      </div>
      <div v-if="form.form_type == 1" class="mb-3">
        <label class="block mb-2" for="company_name">{{
          $t('companyName')
        }}</label>
        <InputText
          v-model="form.company_name"
          id="company_name"
          class="w-full"
          :class="{ 'p-invalid': errors.company_name }"
        />
        <small class="text-gray-500">{{ $t('companyNameExample') }}</small>
        <div v-if="errors.company_name">
          <small
            v-for="(error, i) in errors.company_name"
            :key="i"
            class="p-error"
            >{{ error }}</small
          >
        </div>
      </div>
      <div class="mb-3">
        <label v-if="form.form_type == 1" class="block mb-2" for="name">{{
          $t('fullNameReg1')
        }}</label>
        <label v-else class="block mb-2" for="name">{{ $t('fullName') }}</label>
        <InputText
          v-model="form.name"
          id="name"
          class="w-full"
          :class="{ 'p-invalid': errors.name }"
        />
        <div v-if="errors.name">
          <small v-for="(error, i) in errors.name" :key="i" class="p-error">{{
            error
          }}</small>
        </div>
      </div>
      <div v-if="form.form_type == 1" class="mb-3">
        <label class="block mb-2" for="company_identifier">{{
          $t('company.identifier0')
        }}</label>
        <InputText
          v-model="form.company_identifier"
          id="company_identifier"
          class="w-full"
          :class="{ 'p-invalid': errors.company_identifier }"
        />
        <div v-if="errors.company_identifier">
          <small
            v-for="(error, i) in errors.company_identifier"
            :key="i"
            class="p-error"
            >{{ error }}</small
          >
        </div>
      </div>
      <div class="mb-3">
        <label class="block mb-2" for="email">{{ $t('enterEmail') }}</label>
        <InputText
          v-model="form.email"
          id="email"
          class="w-full"
          :class="{ 'p-invalid': errors.email }"
        />
        <div v-if="errors.email">
          <small v-for="(error, i) in errors.email" :key="i" class="p-error">{{
            error
          }}</small>
        </div>
      </div>
      <div class="mb-3">
        <label class="block mb-2" for="phone">{{ $t('phone') }}</label>
        <div class="p-inputgroup">
          <span v-if="currentCountry" class="p-inputgroup-addon"
            >+{{ currentCountry.phone_code }}</span
          >
          <InputMask
            v-model="form.phone"
            id="phone"
            class="w-full"
            :class="{ 'p-invalid': errors.phone }"
            mask="(999) 999-99-99"
          />
        </div>

        <div v-if="errors.phone">
          <small v-for="(error, i) in errors.phone" :key="i" class="p-error">{{
            error
          }}</small>
        </div>
      </div>
      <div class="mb-3">
        <label class="block mb-2" for="password">{{ $t('regPassword') }}</label>
        <Password
          v-model="form.password"
          id="password"
          :feedback="false"
          class="w-full"
          :class="{ 'p-invalid': errors.password }"
          inputClass="w-full"
          toggleMask
        />
        <div v-if="errors.password">
          <small
            v-for="(error, i) in errors.password"
            :key="i"
            class="p-error"
            >{{ error }}</small
          >
        </div>
      </div>
      <div class="mb-3">
        <label class="block mb-2" for="password_confirm">{{
          $t('passwordConfirm')
        }}</label>
        <Password
          v-model="form.password_confirm"
          id="password_confirm"
          :feedback="false"
          class="w-full"
          :class="{ 'p-invalid': errors.password_confirm }"
          inputClass="w-full"
        />
        <div v-if="errors.password_confirm">
          <small
            v-for="(error, i) in errors.password_confirm"
            :key="i"
            class="p-error"
            >{{ error }}</small
          >
        </div>
      </div>
      <div class="mb-3">
        <Checkbox
          inputId="agree_rules"
          v-model="form.agree_rules"
          :binary="true"
          class="align-middle"
        />
        <label class="ml-2"
          >{{ $t('agreeWith') }}
          <span
            @click="showConditions = !showConditions"
            class="cursor-pointer text-blue-600 hover:text-blue-500"
            >{{ $t('rules') }}</span
          >
          {{ $t('agreePlace') }}
        </label>
        <div v-if="errors.agree_rules">
          <small
            v-for="(error, i) in errors.agree_rules"
            :key="i"
            class="p-error"
            >{{ error }}</small
          >
        </div>
      </div>
      <div class="mb-5">
        <Checkbox
          inputId="agree_personal"
          v-model="form.agree_personal"
          :binary="true"
          class="align-middle"
        />
        <label class="ml-2">
          {{ $t('agreeWith') }}
          <span
            @click="showPersonalData = !showPersonalData"
            class="cursor-pointer text-blue-600 hover:text-blue-500"
            >{{ $t('rules') }}</span
          >
          {{ $t('agreePersonal') }}
        </label>
        <div v-if="errors.agree_personal">
          <small
            v-for="(error, i) in errors.agree_personal"
            :key="i"
            class="p-error"
            >{{ error }}</small
          >
        </div>
      </div>
      <div class="mb-3">
        <Button
          class="px-10 w-full"
          type="button"
          :label="$t('signUp')"
          :loading="submitting"
          @click="register"
        />
      </div>
    </form>
  </div>

  <!-- Условия -->
  <Dialog
    v-model:visible="showConditions"
    modal
    position="top"
    header="Правила и условия использования"
    :style="{ width: '50rem' }"
    :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
  >
    <Conditions />
  </Dialog>

  <Dialog
    v-model:visible="showPersonalData"
    modal
    position="top"
    header="Правила и условия использования"
    :style="{ width: '50rem' }"
    :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
  >
    <PersonalData />
  </Dialog>
</template>

<script>
import { useAuthStore } from '@/store/auth.store'
import Password from 'primevue/password'
import InputMask from 'primevue/inputmask'
import { ref, watch } from 'vue'
import countryApi from '@/api/country'
import Conditions from '@/components/modals/Conditions.vue'
import PersonalData from '@/components/modals/PersonalData.vue'

export default {
  components: { Password, InputMask, Conditions, PersonalData },
  setup() {
    const store = useAuthStore()
    const submitting = ref(false)
    const errors = ref([])
    const countries = ref([])
    const currentCountry = ref(null)
    const country = ref(null)
    const showConditions = ref(false)
    const showPersonalData = ref(false)
    // Get countries list
    countryApi.getCountries().then((response) => {
      countries.value = response.data
    })

    // Set regions for selected country
    watch(country, (val) => {
      currentCountry.value = countries.value.filter((country) => {
        return country.code == val
      })[0]
    })

    // Form
    const form = ref({
      type: null,
      form_type: null,
      name: null,
      company_identifier: null,
      email: null,
      phone: null,
      password: null,
      password_confirm: null,
      region: null,
      agree_rules: false,
      agree_personal: false,
      company_name: null,
    })

    watch(form.value, (val) => {
      if (val.type == '2') {
        form.value.form_type = '1'
      }
    })

    // Register
    const register = () => {
      submitting.value = true
      errors.value = []

      // Set phone code
      store
        .register(form.value)
        .then(() => {
          submitting.value = false
        })
        .catch((error) => {
          errors.value = error.response.data.errors
          submitting.value = false
        })
    }

    return {
      form,
      register,
      countries,
      country,
      currentCountry,
      submitting,
      errors,
      showConditions,
      showPersonalData,
    }
  },
}
</script>
