<template>
  <div class="mb-4">
    <!-- Search -->
    <span class="p-input-icon-left w-4/12 mr-3">
      <i class="pi pi-search" />
      <InputText
        type="text"
        v-model="filters.search"
        :placeholder="$t('search')"
        class="w-full"
        disabled
      />
    </span>
    <!-- Show filters -->
    <Button
      @click="showFilters = !showFilters"
      :label="$t('filter')"
      icon="pi pi-sliders-h"
      class="mr-4"
    />
    <Button
      :label="$t('reset')"
      @click="resetFilters"
      severity="secondary"
      class="p-button-secondary p-button-text"
    />
  </div>

  <Dialog
    v-model:visible="showFilters"
    modal
    :header="$t('filter')"
    position="top"
    :style="{ width: '30vw' }"
  >
    <!-- Date period -->
    <div class="mb-3">
      <Calendar
        v-model="date"
        showIcon
        dateFormat="dd.mm.yy"
        selectionMode="range"
        :numberOfMonths="2"
        :placeholder="$t('period')"
        hideOnRangeSelection
        @hide="tf"
        class="w-full"
      />
    </div>

    <!-- Category -->
    <TreeSelect
      v-model="selectedCategory"
      :options="preparedTree"
      selectionMode="checkbox"
      :placeholder="$t('selectCategory')"
      class="w-full mb-3"
    />

    <!-- Brand -->
    <div class="mb-3">
      <FormInputText
        v-model="filters['f[brand][like]']"
        :id="`brand`"
        :placeholder="$t('brand')"
      />
    </div>

    <!-- Owner type -->
    <div class="mb-3">
      <div class="flex flex-wrap gap-10">
        <div class="flex align-items-center">
          <Checkbox
            v-model="ownerType"
            inputId="userType1"
            name="userType"
            value="1"
            class="mt-1"
          />
          <label for="userType1" class="ml-2">{{ $t('fromCustomer') }}</label>
        </div>
        <div class="flex align-items-center">
          <Checkbox
            v-model="ownerType"
            inputId="userType2"
            name="userType"
            value="2"
            class="mt-1"
          />
          <label for="userType2" class="ml-2">{{ $t('fromproducer') }}</label>
        </div>
      </div>
    </div>
    <template #footer>
      <Button
        :label="$t('reset')"
        class="p-button-secondary p-button-outlined"
        outlined
        icon="pi pi-times"
        @click="resetFilters"
        text
      />
      <Button :label="$t('apply')" icon="pi pi-check" @click="applyFilters" />
    </template>
  </Dialog>
</template>

<script>
import { ref } from 'vue'
import { useDictionaryStore } from '@/store/dictionary.store'
import Calendar from 'primevue/calendar'
import FormInputText from '@/components/form/FormInputText.vue'
import queryString from 'query-string'
import router from '@/router'
import TreeSelect from 'primevue/treeselect'

export default {
  components: { Calendar, FormInputText, TreeSelect },
  setup(props, context) {
    const showFilters = ref(false)

    // Filters
    const filters = ref({})
    const date = ref([])
    const ownerType = ref()

    // Categories
    const dictionaryStore = useDictionaryStore()
    const { preparedTree, preparedtreeIds } = dictionaryStore
    const selectedCategory = ref({})

    // Init
    if (location.search) {
      filters.value = queryString.parse(location.search)
      console.log(filters.value)
      // Init date period
      date.value[0] = new Date(filters.value['f[dateBeg][ge]'])
      date.value[1] = new Date(filters.value['f[dateBeg][le]'])

      // Init categories
      if (filters.value['f[category][in][]']) {
        filters.value['f[category][in][]'].forEach((categoryId) => {
          for (const [key, value] of Object.entries(preparedtreeIds)) {
            if (value == categoryId) {
              selectedCategory.value[key] = {
                checked: true,
                partialChecked: false,
              }
            }
          }
        })
      }
    }

    // Reset filters
    const resetFilters = () => {
      showFilters.value = false
      filters.value = {}
      selectedCategory.value = {}
      date.value = []
      router.push('/tenders')
      context.emit('applyFilters')
    }

    // Apply filters
    const applyFilters = () => {
      // Dates
      if (date.value.length > 0) {
        filters.value['f[dateBeg][ge]'] = date.value[0].toISOString()
        filters.value['f[dateBeg][le]'] = date.value[1].toISOString()
      }

      // Owner type
      filters.value['f[owner.type][in][]'] = ownerType.value

      // Categories
      if (selectedCategory.value) {
        console.log(selectedCategory.value)
        let categoryIds = []
        for (const [key, value] of Object.entries(selectedCategory.value)) {
          if (value.checked) {
            categoryIds.push(preparedtreeIds[key])
          }
        }
        filters.value['f[category][in][]'] = categoryIds
      }

      // Push query
      const query = queryString.stringify(filters.value)
      router.push(`/tenders?${query}`)
      context.emit('applyFilters', query)

      // Hide filters
      showFilters.value = false
      filters.value = {}
    }

    // // Set categories filter
    // const updateCategories = (values) => {
    //   let categoryIds = []
    //   for (const [key, value] of Object.entries(values)) {
    //     if (value.checked) {
    //       categoryIds.push(preparedtreeIds[key])
    //     }
    //   }
    //   filters.value['f[category][in][]'] = categoryIds
    // }

    return {
      showFilters,
      date,
      filters,
      preparedTree,
      ownerType,
      selectedCategory,
      resetFilters,
      applyFilters,
    }
  },
}
</script>
