<template>
  <h3 v-if="requests.length > 0" class="text-2xl mb-5">
    {{ $t('requestsList') }}
  </h3>
  <div
    v-for="(request, i) in requests"
    :key="i"
    class="border-gray-300 rounded-lg border p-5 mb-5"
  >
    <div class="grid grid-cols-2 mb-7">
      <div>
        <Avatar
          v-if="request.executor"
          :label="request.executor.name.charAt(0).toUpperCase()"
          class="mr-5 float-left"
          size="xlarge"
          shape="circle"
        />
        <div class="float-left mr-10">
          <a v-if="request.executor" href="#">{{ request.executor.name }}</a>
          <div class="text-gray-400 mt-1">{{ request.createdAt }}</div>
        </div>
        <div v-if="request.company">
          <a href="#">{{ request.company.name }}</a>
        </div>
      </div>
      <div class="text-right">
        <ul class="inline-list">
          <li>{{ $t('rating') }}: <b>0</b></li>
          <li>{{ $t('tenders_finished') }}: <b>0</b></li>
          <li>{{ $t('tenders_refused') }}: <b>0</b></li>
        </ul>
      </div>
    </div>
    <div class="text-xl mb-7">
      <span class="mr-10"
        >{{ $t('request_sum') }}: <b>{{ request.sum.toLocaleString() }}</b
        >&nbsp;&#8381;</span
      >
      <span
        >{{ $t('execution_time') }}: <b>{{ request.executionTime }}</b></span
      >
    </div>
    <div class="mb-7">
      <h4 class="mb-1">{{ $t('comment') }}</h4>
      <div class="italic text-lg">{{ request.note }}</div>
    </div>
    <div v-if="request.status == 0">
      <div v-if="owner.id == user.id">
        <Button
          :label="$t('select_executor')"
          icon="pi pi-check"
          @click="approveRequest(request)"
          class="mr-5 p-button-outlined"
        />
        <Button
          :label="$t('deny')"
          @click="denyRequest(request)"
          text
          class="p-button-secondary p-button-outlined"
        />
      </div>
    </div>
    <div v-if="owner.id != user.id || request.status > 0">
      <InlineMessage v-if="request.status == 0" severity="info">{{
        $t('requestPending')
      }}</InlineMessage>
      <InlineMessage v-if="request.status == 1" severity="success">{{
        $t('requestApproved')
      }}</InlineMessage>
      <InlineMessage
        v-if="request.status == 2"
        severity="error"
        class="bg-red-50"
        >{{ $t('requestDenied') }}</InlineMessage
      >
      <InlineMessage v-if="request.status == 3" severity="success">{{
        $t('executionApproved')
      }}</InlineMessage>
    </div>
    <div
      v-if="request.status == 1 && request.executor.id == user.id"
      class="mt-5"
    >
      <Button
        :label="$t('approveTenderExecute')"
        icon="pi pi-check"
        @click="approveExecute(request)"
      />
    </div>
  </div>

  <!-- Confirm dialog -->
  <ConfirmDialog group="status" :draggable="false" class="w-1/3">
    <template #message="slotProps">
      <div>
        <div class="mb-5">
          <span>{{ slotProps.message.message }}</span>
        </div>
        <div>
          <b class="text-xl">{{ currentRequest.executor.name }}</b>
          <div v-if="currentRequest.company" class="mt-2">
            {{ currentRequest.company.name }}
          </div>
        </div>
      </div>
    </template>
  </ConfirmDialog>

  <ConfirmDialog group="approve"></ConfirmDialog>

  <!-- <div v-if="!requests.length" class="border-gray-300 rounded-lg border p-5 bg-slate-50">
    <p>{{ $t('noRequests') }}</p>
  </div> -->
</template>

<script>
import Avatar from 'primevue/avatar'
import tenderApi from '@/api/tender'
import { useConfirm } from 'primevue/useconfirm'
import i18n from '@/i18n'
import { useAuthStore } from '@/store/auth.store'
import { ref } from 'vue'

export default {
  props: ['requests', 'owner'],
  components: { Avatar },
  setup(props, context) {
    const confirm = useConfirm()
    const currentRequest = ref(null)

    // Approve tender request
    const approveRequest = (request) => {
      currentRequest.value = request
      confirm.require({
        group: 'status',
        header: i18n.global.t('executorConfirmation'),
        message: i18n.global.t('confirmExecutorApprove'),
        accept: () => {
          tenderApi
            .approveRequest(request.id)
            .then(() => {
              context.emit('requestApproved')
            })
            .catch((error) => {
              console.log(error.response.data)
            })

          currentRequest.value = null
        },
        reject: () => {
          currentRequest.value = null
        },
      })
    }

    // Deny tender request
    const denyRequest = (request) => {
      currentRequest.value = request
      confirm.require({
        group: 'status',
        header: i18n.global.t('executorDeny'),
        message: i18n.global.t('confirmExecutorDeny'),
        acceptLabel: i18n.global.t('deny'),
        // acceptClass: 'p-button-danger',
        accept: () => {
          tenderApi
            .denyRequest(request.id)
            .then(() => {
              context.emit('requestDenied', request)
            })
            .catch((error) => {
              console.log(error.response.data)
            })

          currentRequest.value = null
        },
        reject: () => {
          currentRequest.value = null
        },
      })
    }

    // Approve tender by executor
    const approveExecute = (request) => {
      confirm.require({
        group: 'approve',
        header: i18n.global.t('approveExecute'),
        message: i18n.global.t('doyouApproveRequestExecute'),
        acceptLabel: i18n.global.t('approve'),
        icon: 'pi pi-question-circle',
        // acceptClass: 'p-button-danger',
        accept: () => {
          tenderApi
            .approveExecute(request.id)
            .then(() => {
              context.emit('executeApproved', request)
            })
            .catch((error) => {
              console.log(error.response.data)
            })
        },
      })
    }

    // User
    const authStore = useAuthStore()
    const { user } = authStore

    return { approveRequest, denyRequest, approveExecute, user, currentRequest }
  },
}
</script>
