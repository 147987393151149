import { serialize } from 'object-to-formdata'

/**
 * Конвертация данных в бинарный вид
 */
export function convertFormData(data) {
  return serialize(data)
}

// Category
export function getCategoryPath(data) {
  if (!data) {
    return []
  }

  let path = []

  const getPath = (category) => {
    for (const [key, value] of Object.entries(category)) {
      if (key == 'name') {
        path.push(value)
      }
      if (key == 'parent') {
        getPath(value)
      }
    }
  }

  getPath(data)

  return path.reverse()
}
