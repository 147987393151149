<template>
  <div>
    <TabMenu :model="tendersTabs" :exact="false" class="mb-5 -mt-5">
      <template #item="{ item }">
        <router-link :to="item.to" class="p-menuitem-link">
          <span :class="`tender-status-${item.status} mr-2 w-3 h-3`"></span>
          <span class="p-menuitem-text">{{ item.label }}</span>
        </router-link>
      </template>
    </TabMenu>

    <!-- FILTERS -->
    <TenderFilters @applyFilters="getTenders" />

    <!-- TENDERS LIST -->
    <Card>
      <template #title>
        {{ $t('nav.tenders') }}
        <router-link to="/tenders/add">
          <Button
            :label="$t('addTender')"
            icon="pi pi-plus"
            class="p-button-sm float-right"
            :disabled="user.status == 0"
          />
        </router-link>
      </template>
      <template #content>
        <!-- <div class="text-center"><ProgressSpinner v-if="loading" /></div> -->
        <DataTable
          v-if="!loading"
          :value="tenders"
          :paginator="true"
          :rows="20"
          dataKey="id"
          :rowHover="true"
          stripedRows
          :loading="loading"
          selectionMode="single"
          v-model:selection="selectedTender"
          @rowSelect="onTednerSelect"
          :metaKeySelection="false"
        >
          <Column field="status" class="w-0">
            <template #body="{ data }">
              <span
                :class="`tender-status-${data.status}`"
                v-tooltip.top="$t('tenderStatus' + data.status)"
              ></span>
            </template>
          </Column>
          <Column field="id" header="ID">
            <template #body="{ data }">
              <router-link :to="`/tenders/${data.id}`"
                ><b>{{ data.id }}</b>
              </router-link>
            </template>
          </Column>
          <Column
            field="category.name"
            :header="$t('tendersTable.category')"
          ></Column>
          <Column field="brand" :header="$t('tendersTable.brand')"></Column>
          <Column
            field="date_beg"
            :header="$t('tendersTable.date_beg')"
          ></Column>
          <Column
            field="duration"
            :header="$t('tendersTable.duration')"
          ></Column>
          <Column field="company.name" :header="$t('tendersTable.company')">
            <!-- @TODO: -->
            <template #body="{ data }">
              <a v-if="data.company" :href="`/companies/${data.company.id}`">{{
                data.company.name
              }}</a>
              <a v-else :href="`/users/${data.owner.id}`">{{
                data.owner.name
              }}</a>
            </template>
          </Column>
          <Column field="region" :header="$t('tendersTable.region')"></Column>
        </DataTable>
      </template>
    </Card>
  </div>
</template>

<script>
import { ref } from 'vue'
// import ProgressSpinner from 'primevue/progressspinner'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import tenderApi from '@/api/tender'
import { useAuthStore } from '@/store/auth.store'
import TenderFilters from '@/components/tenders/TenderFilters.vue'
import router from '@/router'

export default {
  components: { DataTable, Column, TenderFilters },
  setup() {
    const loading = ref(false)
    const tenders = ref([])

    const authStore = useAuthStore()
    const { user } = authStore

    const getTenders = (query) => {
      tenderApi.getTenders(query).then((response) => {
        tenders.value = response.data
      })
    }

    getTenders(location.search)

    const selectedTender = ref()
    const onTednerSelect = (event) => {
      router.push(`/tenders/${event.data.id}`)
    }

    return {
      loading,
      tenders,
      user,
      selectedTender,
      getTenders,
      onTednerSelect,
    }
  },
}
</script>
