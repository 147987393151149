import { createApp } from 'vue'

// Vue plugins
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n'

// Prime Vue
import PrimeVue from 'primevue/config'

// Prime Vue Plugins
import ToastService from 'primevue/toastservice'

// Prime Vue Components
import Dialog from 'primevue/dialog'
import ConfirmDialog from 'primevue/confirmdialog'
import Button from 'primevue/button'
import InputText from 'primevue/inputtext'
import Textarea from 'primevue/textarea'
import Toast from 'primevue/toast'
import Card from 'primevue/card'
import BadgeDirective from 'primevue/badgedirective'
import Checkbox from 'primevue/checkbox'
import RadioButton from 'primevue/radiobutton'
import Dropdown from 'primevue/dropdown'
import Tooltip from 'primevue/tooltip'
import ConfirmationService from 'primevue/confirmationservice'
import InlineMessage from 'primevue/inlinemessage'

// CSS
import './assets/tailwind.css'
import './assets/scss/app.scss'
import 'primevue/resources/themes/tailwind-light/theme.css'
import 'primevue/resources/primevue.min.css'
import 'primeicons/primeicons.css'
import { createPinia } from 'pinia'

// Locale configs
import calendarLocale from './configs/locale/ru.js'

const app = createApp(App).use(createPinia())
app.use(i18n)
app.use(store)
app.use(router)
app.use(PrimeVue, calendarLocale)
app.use(ToastService)
app.use(ConfirmationService)
// Components
app.component('Dialog', Dialog)
app.component('ConfirmDialog', ConfirmDialog)
app.component('Button', Button)
app.component('InputText', InputText)
app.component('Textarea', Textarea)
app.component('Toast', Toast)
app.component('Card', Card)
app.component('Checkbox', Checkbox)
app.component('Dropdown', Dropdown)
app.component('RadioButton', RadioButton)
app.component('InlineMessage', InlineMessage)
// Directives
app.directive('badge', BadgeDirective)
app.directive('tooltip', Tooltip)
// Mount
app.mount('#app')
