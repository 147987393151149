<template>
  <TabMenu :model="tendersTabs" :exact="false" class="mb-5 -mt-5">
    <template #item="{ item }">
      <router-link :to="item.to" class="p-menuitem-link">
        <span :class="`tender-status-${item.status} mr-2 w-3 h-3`"></span>
        <span class="p-menuitem-text">{{ item.label }}</span>
      </router-link>
    </template>
  </TabMenu>
  <Card>
    <template #title>
      {{ $t('nav.myTenders') }}
      <router-link to="/tenders/add">
        <Button
          :label="$t('addTender')"
          icon="pi pi-plus"
          class="p-button-sm float-right"
          :disabled="user.status == 0"
        />
      </router-link>
    </template>
    <template #content>
      <!-- <div class="text-center"><ProgressSpinner v-if="loading" /></div> -->
      <DataTable
        v-if="!loading"
        :value="tenders"
        :paginator="true"
        :rows="20"
        dataKey="id"
        :rowHover="true"
        stripedRows
        :loading="loading"
        selectionMode="single"
        v-model:selection="selectedTender"
        @rowSelect="onTednerSelect"
        :metaKeySelection="false"
      >
        <Column field="status" class="w-0">
          <template #body="{ data }">
            <span
              :class="`tender-status-${data.status}`"
              v-tooltip.top="$t('tenderStatus' + data.status)"
            ></span>
          </template>
        </Column>
        <Column field="id" header="ID">
          <template #body="{ data }">
            <router-link :to="`/tenders/${data.id}`"
              ><b>{{ data.id }}</b>
            </router-link>
          </template>
        </Column>
        <Column
          field="category.name"
          :header="$t('tendersTable.category')"
        ></Column>
        <Column field="brand" :header="$t('tendersTable.brand')"></Column>
        <Column field="date_beg" :header="$t('tendersTable.date_beg')"></Column>
        <Column field="duration" :header="$t('tendersTable.duration')"></Column>
        <Column field="company.name" :header="$t('tendersTable.company')">
          <template #body="{ data }">
            <a v-if="data.company" :href="`/companies/${data.company.id}`">{{
              data.company.name
            }}</a>
            <a v-else :href="`/users/${data.owner.id}`">{{
              data.owner.name
            }}</a>
          </template>
        </Column>
        <Column field="region" :header="$t('tendersTable.region')"></Column>
      </DataTable>
    </template>
  </Card>
</template>

<script>
import { ref, watch } from 'vue'
import { useAuthStore } from '@/store/auth.store'
import tenderApi from '@/api/tender'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import i18n from '@/i18n'
import TabMenu from 'primevue/tabmenu'
import router from '@/router'
import { useRoute } from 'vue-router'

export default {
  components: { DataTable, Column, TabMenu },
  setup() {
    // User
    const authStore = useAuthStore()
    const { user } = authStore
    // Tenders
    const tenders = ref([])
    // Route
    const route = useRoute()

    // Get my tenders
    const getTenders = () => {
      const query =
        route.name == 'tenders_my'
          ? '?f[status][not_in][]=0&f[status][not_in][]=8'
          : '?f[status][in][]=0&f[status][in][]=8'
      tenderApi.getMyTenders(query).then((response) => {
        tenders.value = response.data
      })
    }

    getTenders()

    watch(
      () => route.name,
      () => {
        getTenders()
      }
    )

    const selectedTender = ref()
    const onTednerSelect = (event) => {
      router.push(`/tenders/${event.data.id}`)
    }

    // Tab menu
    const tendersTabs = [
      {
        label: i18n.global.t('activeTenders'),
        icon: 'pi pi-fw pi-circle-fill',
        to: '/tenders/my',
        status: 1,
      },
      {
        label: i18n.global.t('closedTenders'),
        icon: 'pi pi-fw pi-circle-fill',
        to: '/tenders/my/closed',
        status: 0,
      },
    ]

    return { user, tenders, tendersTabs, selectedTender, onTednerSelect }
  },
}
</script>
