import api from './axios'

const fileApi = {
  downloadFile(id) {
    return api.get(`/file/${id}`, {
      responseType: 'blob',
    })
  },
}

export default fileApi
