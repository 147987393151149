<template>
  <div class="py-20 px-5 m-auto w-full text-white">
    <h3 class="text-3xl text-center mb-5">Авторизация</h3>
    <form @submit.prevent="login">
      <div class="mb-3">
        <label class="block mb-2" for="username">E-mail</label>
        <InputText
          v-model="form.username"
          id="username"
          type="username"
          aria-describedby="username-help"
          class="w-full"
        />
        <!-- <small id="username1-help">Enter your username to reset your password.</small> -->
      </div>
      <div class="mb-3">
        <label class="block mb-2" for="password">{{ $t('password') }}</label>
        <InputText
          v-model="form.password"
          id="password"
          type="password"
          aria-describedby="password-help"
          class="w-full"
        />
        <!-- <small id="username1-help">Enter your username to reset your password.</small> -->
      </div>
      <div class="mb-5">
        <Checkbox
          inputId="rememberMe"
          v-model="form.rememberMe"
          :binary="true"
          class="align-middle"
        />
        <label for="rememberMe" class="ml-2">{{ $t('rememberMe') }}</label>
      </div>
      <div v-if="errors.error" class="mb-5">
        <small class="text-red-600">{{ errors.error }}</small>
      </div>
      <div class="mb-10">
        <Button
          class="px-10 w-full bg-white text-violet-600"
          type="button"
          :label="$t('signIn')"
          :loading="submitting"
          @click="login"
        />
      </div>
      <div class="text-center">
        Нет аккаунта?
        <!-- $emit('update:modelValue', $event.target.value) -->
        <span
          @click="$emit('showReg')"
          class="text-white underline cursor-pointer"
          >{{ $t('signUpNow') }}</span
        >
      </div>
    </form>
  </div>
</template>

<script>
import { useAuthStore } from '@/store/auth.store'
import { ref } from 'vue'

export default {
  setup() {
    const store = useAuthStore()
    const form = ref({
      username: null,
      password: null,
      rememberMe: false,
    })
    const submitting = ref(false)
    const errors = ref({})

    // const showReg = () => {

    // }

    const login = () => {
      submitting.value = true
      store
        .login(form.value.username, form.value.password, form.value.rememberMe)
        .then(() => (submitting.value = false))
        .catch((error) => {
          errors.value = error.response.data
          submitting.value = false
        })
    }

    return { form, submitting, login, errors }
  },
}
</script>
