<template>
  <label v-if="label" class="block mb-2" for="name">{{ label }}</label>
  <span :class="{ 'p-input-icon-left': icon }">
    <i v-if="icon" :class="[icon]" />
    <InputText
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)"
      :id="id"
      type="text"
      :class="{ 'p-invalid': errors }"
      class="w-full"
      :disabled="disabled"
      :placeholder="placeholder"
    />
  </span>
  <div v-if="errors">
    <small v-for="(error, i) in errors" :key="i" class="p-error">{{
      error
    }}</small>
  </div>
</template>

<script>
export default {
  props: [
    'modelValue',
    'id',
    'label',
    'errors',
    'icon',
    'disabled',
    'placeholder',
    'hideLabel',
  ],
  setup() {},
}
</script>
