<template>
  <Toast position="bottom-right" />
  <hr class="mb-7" />
  <div>
    <Button
      v-if="!showForm"
      @click="showForm = true"
      :label="$t('sendTenderRequest')"
      icon="pi pi-send"
      :disabled="user.status == 0"
      class="mb-5"
    />
    <div
      v-if="showForm"
      class="border-gray-300 rounded-lg border p-5 bg-slate-50"
    >
      <h3 class="text-2xl mb-5">{{ $t('tender_request') }}</h3>
      <div class="grid grid-cols-4 gap-8 mb-5">
        <div>
          <label class="block mb-2" for="sum">{{ $t('request_sum') }}</label>
          <div class="p-inputgroup w-full md:w-30rem">
            <InputNumber
              id="sum"
              v-model="form.sum"
              :placeholder="$t('sum')"
              currency="RUB"
              locale="ru"
              minFractionDigits="2"
              :class="{ 'p-invalid': errors.sum }"
            />
            <span class="p-inputgroup-addon">&#8381;</span>
          </div>
          <div v-if="errors.sum">
            <small v-for="(error, i) in errors.sum" :key="i" class="p-error">{{
              error
            }}</small>
          </div>
        </div>
        <div>
          <FormInputText
            v-model="form.execution_time"
            :id="`execution_time`"
            :label="$t('execution_time')"
            :errors="errors.execution_time"
          />
          <div class="pt-1">
            <span class="text-sm">Например: <i>30 дней или 2 месяца</i></span>
          </div>
        </div>
      </div>
      <div class="mb-5">
        <label class="block mb-2" for="note">{{ $t('comment') }}</label>
        <Textarea v-model="form.note" rows="3" id="note" class="w-full" />
      </div>
      <div>
        <Button
          @click="send"
          :label="$t('send_request')"
          icon="pi pi-send"
          :loading="submitting"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { ref, defineComponent } from 'vue'
import FormInputText from '@/components/form/FormInputText.vue'
import InputNumber from 'primevue/inputnumber'
import tenderApi from '@/api/tender'
import { useAuthStore } from '@/store/auth.store'
import { useToast } from 'primevue/usetoast'
import i18n from '@/i18n'

export default defineComponent({
  props: ['tender'],
  components: { FormInputText, InputNumber },
  setup(props, context) {
    const showForm = ref(false)
    const form = ref({
      sum: null,
      execution_time: null,
      note: null,
    })
    const errors = ref({})
    const submitting = ref(false)

    // User
    const authStore = useAuthStore()
    const { user } = authStore

    // Toast
    const toast = useToast()

    // Send tender request
    const send = () => {
      submitting.value = true
      tenderApi
        .request(props.tender.id, form.value)
        .then(() => {
          context.emit('tenderRequestSent')

          toast.add({
            severity: 'success',
            summary: i18n.global.t('requestSent'),
            detail: i18n.global.t('requstSuccessfullySent'),
            life: 3000,
          })

          form.value = {
            sum: null,
            execution_time: null,
            note: null,
          }
          showForm.value = false
        })
        .catch((error) => {
          if (error.response.data.errors) {
            errors.value = error.response.data.errors
          }
        })
        .finally(() => {
          submitting.value = false
        })
    }

    return { showForm, send, form, errors, user }
  },
})
</script>
