<template>
  <Card>
    <template #title> {{ $t('ticket') }} #{{ id }} </template>
    <template #content>
      <div class="text-xl mb=5">
        <div class="mb-3">{{ $t('user') }}: {{ ticket.client.name }}</div>
        <div class="mb-3">{{ $t('date') }}: {{ ticket.createdAt }}</div>
        <div class="mb-3">
          <h3>
            <b>{{ $t('message') }}</b>
          </h3>
          <div>{{ ticket.message }}</div>
        </div>
      </div>
      <TicketChat :id="id" />
    </template>
  </Card>
</template>

<script>
import router from '@/router'
import { ref } from 'vue'
import supportApi from '@/api/support'
import TicketChat from '@/components/support/TicketChat.vue'

export default {
  components: { TicketChat },
  setup() {
    const id = router.currentRoute.value.params.id
    const ticket = ref({
      client: {},
    })

    supportApi.getTicket(id).then((response) => {
      ticket.value = response.data
    })

    return { id, ticket }
  },
}
</script>
