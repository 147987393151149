<template>
  <div class="flex flex-col h-screen justify-between">
    <div class="container mx-auto pt-5 md:pt-44">
      <div class="grid md:grid-cols-2">
        <div>
          <img src="@/assets/img/logo.svg" alt="IZBIZ" class="w-72 mb-5" />
          <div class="pl-3 pr-3 md:pr-16">
            <h2 class="text-2xl mb-8 font-medium">
              Платформа для
              <span class="text-primary">проведения тендеров</span>
            </h2>
            <p>
              Добро пожаловать на нашу тендерную площадку, где производители и
              заказчики объединены для эффективного взаимодействия. У нас вы
              найдете уникальную возможность заказать производство товаров со
              всего мира, выбрав из множества производителей и лучших условий
              сотрудничества. Гарантируем высокие стандарты качества и
              приглашаем всех заинтересованных присоединиться к нашей площадке
              для расширения бизнеса и увеличения прибыли.
            </p>
            <!-- <p>
              Добро пожаловать на нашу тендерную площадку! Мы предлагаем
              уникальную возможность заказать производство товаров со всего
              мира. Наша площадка объединяет производителей и заказчиков, чтобы
              обеспечить эффективное взаимодействие и выгодные условия для всех
              участников.
            </p>
            <p>
              Мы понимаем, что в современном мире выбор производителя может быть
              сложным и затратным процессом. Наша площадка решает эту проблему,
              предоставляя заказчикам возможность получить доступ к глобальному
              рынку производства, где они могут выбрать из множества
              производителей и наилучших условий сотрудничества.
            </p>
            <p>
              Мы гарантируем, что все производители, размещенные на нашей
              площадке, прошли тщательный отбор и соответствуют высоким
              стандартам качества. Мы убеждены, что наша площадка сможет
              удовлетворить все потребности заказчиков, независимо от их
              размера, отрасли и географического положения.
            </p>
            <p>
              Мы приглашаем всех заинтересованных заказчиков и производителей
              присоединиться к нашей площадке и получить максимальную выгоду от
              сотрудничества. С нами вы сможете расширить свой бизнес, увеличить
              прибыль и обеспечить высокое качество производства ваших товаров.
            </p> -->
          </div>
        </div>
        <div>
          <div class="rounded-lg auth-bg mt-4 ml-5 mr-5 md:ml-0 md:mr-0">
            <LoginForm @showReg="showRegister = true" />
          </div>
        </div>
      </div>
      <div class="auth-bg rounded-lg my-16 ml-5 mr-5 md:ml-0 md:mr-0">
        <div class="grid md:grid-cols-4 text-white py-8 text-center">
          <div class="border-r border-white mb-5 md:mb-0">
            <b class="block text-4xl">110+</b> клиентов
          </div>
          <div class="border-r border-white mb-5 md:mb-0">
            <b class="block text-4xl">500+</b> тендеров
          </div>
          <div class="border-r border-white mb-5 md:mb-0">
            <b class="block text-4xl">1К+</b> оптовых заказов
          </div>
          <div><b class="block text-4xl">70+</b> товаров на платформе</div>
        </div>
      </div>
      <div>
        <h2 class="text-3xl text-center mb-10">Наши преимущества</h2>
        <div
          class="advantages md:grid md:grid-cols-6 md:gap-x-8 text-center ml-5 mr-5 md:ml-0 md:mr-5"
        >
          <div class="mb-5 md:mb-0">
            Широкий выбор производителей со всего мира
          </div>
          <div class="mb-5 md:mb-0">
            Прозрачный и конкурентный процесс тендеров
          </div>
          <div class="mb-5 md:mb-0">Гарантированное качество производства</div>
          <div class="mb-5 md:mb-0">
            Индивидуальный подход к каждому заказчику
          </div>
          <div class="mb-5 md:mb-0">Быстрое и удобное оформление заказа</div>
          <div class="mb-5 md:mb-0">
            Круглосуточная поддержка и консультации
          </div>
        </div>
      </div>
    </div>
    <footer>
      <div class="container mx-auto py-8">
        <div class="md:flex pl-10">
          <img
            src="@/assets/img/logo-white.svg"
            alt="IZBIZ"
            class="w-20 md:mr-20 mb-5 md:mb-0"
          />
          <a
            href="#"
            class="block mb-2 md:mb-0 md:px-20 text-white font-medium text-lg md:border-l border-white"
            >О нас</a
          >
          <span
            @click="showConditions = !showConditions"
            class="block mb-2 md:mb-0 md:px-20 text-white font-medium text-lg md:border-l border-white cursor-pointer"
            >Условия пользования</span
          >
          <span
            @click="showPersonalData = !showPersonalData"
            class="block mb-2 md:mb-0 md:px-20 text-white font-medium text-lg md:border-l border-white cursor-pointer"
            >Условия обработки персональных данных</span
          >
        </div>
      </div>
    </footer>
  </div>
  <Dialog
    v-model:visible="showRegister"
    modal
    :header="$t('registration')"
    position="top"
  >
    <RegisterForm />
  </Dialog>

  <!-- Условия -->
  <Dialog
    v-model:visible="showConditions"
    modal
    position="top"
    header="Правила и условия использования"
    :style="{ width: '50rem' }"
    :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
  >
    <Conditions />
  </Dialog>

  <Dialog
    v-model:visible="showPersonalData"
    modal
    position="top"
    header="Правила и условия использования"
    :style="{ width: '50rem' }"
    :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
  >
    <PersonalData />
  </Dialog>
</template>

<script setup>
import { onMounted, onUnmounted } from 'vue'
import LoginForm from '@/components/auth/LoginForm.vue'
import RegisterForm from '@/components/auth/RegisterForm.vue'
import Conditions from '@/components/modals/Conditions.vue'
import PersonalData from '@/components/modals/PersonalData.vue'
import { ref } from 'vue'

onMounted(() => {
  document.body.classList.add('login')
})
onUnmounted(() => {
  document.body.classList.remove('login')
})

const showRegister = ref(false)
const showConditions = ref(false)
const showPersonalData = ref(false)
</script>

<style lang="scss" scoped>
.login-sidebar {
  width: 42rem;
}
.auth-bg {
  background: rgb(134, 129, 211);
  background: radial-gradient(
    circle,
    rgba(134, 129, 211, 1) 14%,
    rgba(101, 97, 171, 1) 63%
  );
}
p {
  @apply mb-3;
}
ul li {
  @apply mb-2;
}
footer {
  background-color: #6662ac;
}
.advantages div {
  @apply border border-gray-400 rounded-xl p-5;
}
</style>
