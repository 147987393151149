<template>
  <div>
    <form @submit.prevent="save">
      <div class="grid grid-cols-3 gap-x-8 mb-6">
        <div>
          <label class="block mb-2" for="name">{{ $t('fullName') }}</label>
          <InputText
            v-model="form.name"
            id="name"
            type="name"
            aria-describedby="name-help"
            :class="{ 'p-invalid': errors.name }"
            class="w-full"
          />
          <div v-if="errors.name">
            <small v-for="(error, i) in errors.name" :key="i" class="p-error">{{
              error
            }}</small>
          </div>
        </div>
        <div>
          <label class="block mb-2" for="email">E-mail</label>
          <InputText
            v-model="form.email"
            id="email"
            type="email"
            aria-describedby="email-help"
            :class="{ 'p-invalid': errors.email }"
            class="w-full"
          />
          <div v-if="errors.email">
            <small
              v-for="(error, i) in errors.email"
              :key="i"
              class="p-error"
              >{{ error }}</small
            >
          </div>
        </div>
        <div>
          <label class="block mb-2" for="name">{{ $t('phone') }}</label>
          <InputText
            v-model="form.phone"
            id="phone"
            type="phone"
            aria-describedby="phone-help"
            :class="{ 'p-invalid': errors.phone }"
            class="w-full"
          />
          <div v-if="errors.phone">
            <small
              v-for="(error, i) in errors.phone"
              :key="i"
              class="p-error"
              >{{ error }}</small
            >
          </div>
        </div>
      </div>
      <div class="grid grid-cols-3 gap-x-8 mb-6">
        <div>
          <label class="block mb-2" for="name">{{
            $t('userIdentifier')
          }}</label>
          <InputText
            v-model="form.identifier"
            id="identifier"
            type="identifier"
            aria-describedby="identifier-help"
            :class="{ 'p-invalid': errors.identifier }"
            class="w-full"
          />
          <div v-if="errors.identifier">
            <small
              v-for="(error, i) in errors.identifier"
              :key="i"
              class="p-error"
              >{{ error }}</small
            >
          </div>
        </div>
      </div>
      <h3 class="text-lg font-bold mb-5">{{ $t('changePassword') }}</h3>
      <div class="grid grid-cols-3 gap-x-8 mb-6">
        <div>
          <label class="block mb-2" for="old_password">{{
            $t('oldPassword')
          }}</label>
          <Password
            v-model="form.old_password"
            id="old_password"
            :feedback="false"
            class="w-full"
            :class="{ 'p-invalid': errors.old_password }"
            inputClass="w-full"
          />
          <div v-if="errors.old_password">
            <small
              v-for="(error, i) in errors.old_password"
              :key="i"
              class="p-error"
              >{{ error }}</small
            >
          </div>
        </div>
        <div>
          <label class="block mb-2" for="password">{{
            $t('newPassword')
          }}</label>
          <Password
            v-model="form.new_password"
            id="new_password"
            :feedback="false"
            class="w-full"
            :class="{ 'p-invalid': errors.new_password }"
            inputClass="w-full"
          />
          <div v-if="errors.new_password">
            <small
              v-for="(error, i) in errors.new_password"
              :key="i"
              class="p-error"
              >{{ error }}</small
            >
          </div>
        </div>
        <div>
          <label class="block mb-2" for="password_confirm">{{
            $t('passwordConfirm')
          }}</label>
          <Password
            v-model="form.password_confirm"
            id="password_confirm"
            :feedback="false"
            class="w-full"
            :class="{ 'p-invalid': errors.password_confirm }"
            inputClass="w-full"
          />
          <div v-if="errors.password_confirm">
            <small
              v-for="(error, i) in errors.password_confirm"
              :key="i"
              class="p-error"
              >{{ error }}</small
            >
          </div>
        </div>
      </div>
      <div class="pt-2">
        <Button
          type="button"
          :label="$t('save')"
          :loading="submitting"
          @click="save"
          icon="pi pi-save"
        />
      </div>
    </form>
  </div>
</template>

<script>
import { ref } from 'vue'
import { useAuthStore } from '@/store/auth.store'
import Password from 'primevue/password'

export default {
  components: { Password },
  setup() {
    const store = useAuthStore()
    const submitting = ref(false)
    const errors = ref([])

    // Form
    const form = ref({
      email: store.user.email,
      name: store.user.name,
      phone: store.user.phone,
      identifier: store.user.identifier,
      old_password: null,
      new_password: null,
      password_confirm: null,
    })

    const save = () => {
      submitting.value = true
      store
        .updateUser(form.value)
        .then(() => (submitting.value = false))
        .catch((error) => {
          errors.value = error.response.data.errors
          submitting.value = false
        })
    }

    return { form, save, submitting, errors }
  },
}
</script>
