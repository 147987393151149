import api from './axios'
import { convertFormData } from '@/utils/helpers'

const designApi = {
  // Design requests for user
  getDesignRequests() {
    return api.get('/design')
  },
  // Add design request
  addDesignRequest(data) {
    return api.post('/design', convertFormData(data), {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  },
  designInfo(id) {
    return api.get(`/design/${id}`)
  },
  // Send chat message
  sendChatMessage(id, data) {
    return api.post(`/design/${id}/send_message`, data)
  },
  // Get chat messages
  getChatMessages(id) {
    return api.get(`/design/${id}/get_messages`)
  },
  // Set messages seen
  setMessagesSeen(id) {
    return api.post(`/design/${id}/set_messages_seen`)
  },
}

export default designApi
