import api from '../axios'

const adminUsersApi = {
  // Get users list
  getUsers() {
    return api.get('/admin/users')
  },
  // Get user info
  getUser(id) {
    return api.get(`/admin/users/${id}`)
  },
  // Save user
  saveUser(id, data) {
    return api.patch(`/admin/users/${id}`, data)
  },
}

export default adminUsersApi
