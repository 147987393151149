<template>
  <Card>
    <template #title>
      {{ $t('nav.users') }}
    </template>
    <template #content>
      <DataTable
        v-if="!loading"
        :value="users"
        :paginator="true"
        :rows="20"
        dataKey="id"
        :rowHover="true"
      >
        <Column field="id" header="ID"></Column>
        <Column
          field="name"
          :header="$t('fullName')"
          class="font-bold"
        ></Column>
        <Column :header="$t('accountType')">
          <template #body="{ data }">
            {{ $t(`userType${data.type}`) }}
          </template>
        </Column>
        <Column :header="$t('companyTitle')">
          <template #body="{ data }">
            <div
              v-for="(company, index) in data.companies"
              :key="index"
              class="py-1"
            >
              {{ company.name }}
            </div>
          </template>
        </Column>
        <Column field="email" header="E-mail"></Column>
        <Column field="phone" :header="$t('phone')"></Column>
        <Column :header="$t('region')">
          <template #body="{ data }">
            {{ data.region.name }}
          </template>
        </Column>
        <Column :header="$t('status')">
          <template #body="{ data }">
            <span v-if="data.status == 0" class="badge-danger">{{
              $t('inactive')
            }}</span>
            <span v-else class="badge-success">{{ $t('active') }}</span>
          </template>
        </Column>
        <Column>
          <template #body="{ data }">
            <router-link :to="`/admin/users/${data.id}`">
              <Button
                type="button"
                icon="pi pi-cog"
                class="p-button-outlined p-button-secondary p-button-sm"
              ></Button>
            </router-link>
          </template>
        </Column>
      </DataTable>
    </template>
  </Card>
</template>

<script>
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
// import ColumnGroup from 'primevue/columngroup' //optional for column grouping
// import Row from 'primevue/row' //optional for row
import { ref, onMounted } from 'vue'
import adminUsersApi from '@/api/admin/users'

export default {
  components: { DataTable, Column },
  setup() {
    const loading = ref(true)
    const users = ref([])

    // Get new users
    onMounted(() => {
      adminUsersApi.getUsers().then((response) => {
        users.value = response.data.items
        loading.value = false
      })
    })

    // Filters
    //   const filters = ref({
    //     global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    //     name: {
    //         operator: FilterOperator.AND,
    //         constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    //     },
    //     "country.name": {
    //         operator: FilterOperator.AND,
    //         constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    //     },
    //     representative: { value: null, matchMode: FilterMatchMode.IN },
    //     date: {
    //         operator: FilterOperator.AND,
    //         constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
    //     },
    //     balance: {
    //         operator: FilterOperator.AND,
    //         constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    //     },
    //     status: {
    //         operator: FilterOperator.OR,
    //         constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    //     },
    //     activity: { value: null, matchMode: FilterMatchMode.BETWEEN },
    //     verified: { value: null, matchMode: FilterMatchMode.EQUALS },
    // });

    return { users, loading }
  },
}
</script>
