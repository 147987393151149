<template>
  <Card>
    <template #content>
      <div v-if="docs.length > 0" class="mb-10">
        <DataTable :value="docs" showGridlines :rowHover="true">
          <Column field="name" :header="$t('fileName')">
            <template #body="{ data }">
              <span class="text-lg">
                <i class="pi pi-file"></i>&nbsp;<span
                  @click="download.downloadFile(data.id, data.name)"
                  class="text-blue-600 hover:text-blue-500 cursor-pointer"
                  >{{ data.name }}</span
                >
              </span>
            </template>
          </Column>
          <Column field="ext" :header="$t('fileType')"></Column>
          <Column field="size" :header="$t('size')"></Column>
          <Column field="created_at" :header="$t('uploadedAt')"></Column>
          <Column field="uploader" :header="$t('user')">
            <template #body="{ data }">
              <span class="text-blue-600 hover:text-blue-500 cursor-pointer">{{
                data.uploader.name
              }}</span>
            </template>
          </Column>
        </DataTable>
      </div>
      <div class="mb-5">
        <label class="block mb-3 text-xl" for="files">{{
          $t('uploadFiles')
        }}</label>
        <FileUpload
          name="files[]"
          customUpload
          @select="onUpload"
          :multiple="true"
          accept="image/*"
          :maxFileSize="1000000"
          :showUploadButton="false"
          :showCancelButton="false"
          :chooseLabel="$t('selectFiles')"
          ref="fileUpload"
        >
          <template #empty>
            <p>{{ $t('drag_files_here') }}</p>
          </template>
        </FileUpload>
      </div>
      <div>
        <Button
          type="button"
          :label="$t('upload')"
          :loading="submitting"
          @click="save"
          icon="pi pi-upload"
        />
      </div>
    </template>
  </Card>
  <Toast position="bottom-right" />
</template>

<script>
import { ref } from 'vue'
import FileUpload from 'primevue/fileupload'
import tenderApi from '@/api/tender'
import { useToast } from 'primevue/usetoast'
import { useTenderStore } from '@/store/tender.store'
import router from '@/router'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import useDownload from '@/composables/download'

export default {
  components: { FileUpload, DataTable, Column },
  setup() {
    // Toast
    const toast = useToast()

    // Tender
    const tenderStore = useTenderStore()

    if (!tenderStore.tenderInfo.canSeeDocs) {
      router.push('/tenders')
    }

    // Docs
    const docs = ref([])
    const getFiles = () => {
      tenderApi
        .getTenderDocs(router.currentRoute.value.params.id)
        .then((response) => {
          docs.value = response.data
        })
        .catch((error) => {
          toast.add({
            severity: 'error',
            detail: error.response.data.message,
            life: 5000,
          })
        })
    }
    getFiles()

    // Form
    const form = ref({
      files: [],
    })
    const submitting = ref(false)

    // Upload files
    const onUpload = (e) => {
      form.value.files = e.files
    }

    const fileUpload = ref()

    // Save
    const save = () => {
      submitting.value = true
      tenderApi
        .uploadDocs(tenderStore.tenderInfo.id, form.value)
        .then((response) => {
          submitting.value = false
          // Clear
          form.value.files = []
          fileUpload.value.clear()
          // Update
          getFiles()

          toast.add({
            severity: 'success',
            detail: response.data.message,
            life: 3000,
          })
        })
        .catch((error) => {
          toast.add({
            severity: 'error',
            detail: error.response.data.message,
            life: 5000,
          })
        })
    }

    // Download file
    const download = useDownload()

    return { submitting, docs, download, form, fileUpload, onUpload, save }
  },
}
</script>
