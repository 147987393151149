<template>
  <div>
    <form @submit.prevent="save">
      <!-- Date range -->
      <div class="grid grid-cols-4 gap-8 mb-5">
        <div class="sm:col-span-2 2xl:col-span-1">
          <label class="block"
            >{{ $t('date_beg') }}&nbsp;<span class="text-red-600"
              >*</span
            ></label
          >
          <Calendar
            v-model="form.date_beg"
            showIcon
            dateFormat="dd.mm.yy"
            class="mt-2 w-full"
            showTime
            hourFormat="24"
            :minDate="new Date()"
          />
          <div v-if="errors.date_beg">
            <small
              v-for="(error, i) in errors.date_beg"
              :key="i"
              class="p-error"
              >{{ error }}</small
            >
          </div>
        </div>
        <div class="sm:col-span-2 2xl:col-span-1">
          <label class="block"
            >{{ $t('date_end') }}&nbsp;<span class="text-red-600"
              >*</span
            ></label
          >
          <Calendar
            v-model="form.date_end"
            showIcon
            dateFormat="dd.mm.yy"
            class="mt-2 w-full"
            showTime
            hourFormat="24"
            :minDate="form.date_beg"
          />
          <div v-if="errors.date_end">
            <small
              v-for="(error, i) in errors.date_end"
              :key="i"
              class="p-error"
              >{{ error }}</small
            >
          </div>
        </div>
      </div>
      <!-- Categories -->
      <div>
        <label class="block mb-2" for="caltegory"
          >{{ $t('category') }}&nbsp;<span class="text-red-600">*</span>&nbsp;<b
            class="ml-5"
            >{{ currentCategoryLabel }}</b
          ></label
        >
      </div>
      <div class="grid grid-cols-4 gap-8 mb-5">
        <div v-for="(list, i) in currentCategories" :key="i">
          <Listbox
            v-model="categoryPath[i]"
            :options="list"
            @update:modelValue="updateCurrentCategories(i)"
            listStyle="max-height:250px"
            optionLabel="name"
            class="w-full md:w-14rem"
          />
        </div>
      </div>
      <div v-if="errors.category_id" class="mb-5">
        <small
          v-for="(error, i) in errors.category_id"
          :key="i"
          class="p-error"
          >{{ error }}</small
        >
      </div>
      <!-- For producer -->
      <div v-if="user.type == 2" class="grid grid-cols-4 gap-8 mb-5">
        <div>
          <label class="block mb-2" for="sum"
            >{{ $t('start_price') }}&nbsp;<span class="text-red-600"
              >*</span
            ></label
          >
          <div class="p-inputgroup w-full md:w-30rem">
            <InputNumber
              id="sum"
              v-model="form.min_price"
              :placeholder="$t('sum')"
              currency="RUB"
              locale="ru"
              minFractionDigits="2"
              :class="{ 'p-invalid': errors.min_price }"
            />
            <span class="p-inputgroup-addon">&#8381;</span>
          </div>
          <div v-if="errors.min_price">
            <small
              v-for="(error, i) in errors.min_price"
              :key="i"
              class="p-error"
              >{{ error }}</small
            >
          </div>
        </div>
        <div>
          <label class="block mb-2" for="sum"
            >{{ $t('buy_price') }}&nbsp;<span class="text-red-600"
              >*</span
            ></label
          >
          <div class="p-inputgroup w-full md:w-30rem">
            <InputNumber
              id="sum"
              v-model="form.buy_price"
              :placeholder="$t('sum')"
              currency="RUB"
              locale="ru"
              minFractionDigits="2"
              :class="{ 'p-invalid': errors.buy_price }"
            />
            <span class="p-inputgroup-addon">&#8381;</span>
          </div>
          <div v-if="errors.buy_price">
            <small
              v-for="(error, i) in errors.buy_price"
              :key="i"
              class="p-error"
              >{{ error }}</small
            >
          </div>
        </div>
      </div>
      <!-- Brand, Quantity -->
      <div class="grid grid-cols-4 gap-8 mb-5">
        <div>
          <FormInputText
            v-model="form.quantity"
            :id="`quantity`"
            :label="$t('quantity')"
            :errors="errors.quantity"
          />
        </div>
        <div class="col-span-3">
          <FormInputText
            v-model="form.brand"
            :id="`brand`"
            :label="$t('brand')"
            :errors="errors.brand"
          />
        </div>
      </div>
      <!-- Files -->
      <div class="mb-5">
        <div>
          <label class="block mb-2" for="files">{{ $t('files') }}</label>
          <!-- <FileUpload
            mode="basic"
            name="files[]"
            url="./"
            accept="image/*"
            :maxFileSize="1000000"
            @upload="onUpload"
            :auto="true"
            :chooseLabel="$t('selectFiles')"
          /> -->
          <FileUpload
            name="files[]"
            customUpload
            @select="onUpload"
            :multiple="true"
            :maxFileSize="1000000"
            :showUploadButton="false"
            :showCancelButton="false"
            :chooseLabel="$t('selectFiles')"
          >
            <template #empty>
              <p>Drag and drop files to here to upload.</p>
            </template>
          </FileUpload>
        </div>
      </div>
      <div class="grid grid-cols-2 gap-8 mb-5">
        <div class="sm:col-span-2 2xl:col-span-1">
          <label class="block mb-2">{{ $t('pack') }}</label>
          <div class="grid grid-cols-3 gap-8">
            <div>
              <FormInputText
                v-model="form.options.pack.length"
                :placeholder="$t('length')"
                :errors="errors.optionspacklength"
              />
            </div>
            <div>
              <FormInputText
                v-model="form.options.pack.width"
                :placeholder="$t('width')"
                :hideLabel="true"
                :errors="errors.optionspackwidth"
              />
            </div>
            <div>
              <FormInputText
                v-model="form.options.pack.height"
                :placeholder="$t('height')"
                :errors="errors.optionspackheight"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="grid grid-cols-2 gap-8 mb-5">
        <div class="sm:col-span-2 2xl:col-span-1">
          <label class="block mb-2" for="comment">{{ $t('comment') }}</label>
          <Textarea v-model="form.note" rows="5" id="comment" class="w-full" />
        </div>
      </div>
      <div>
        <Button
          type="button"
          :label="$t('createTender')"
          :loading="submitting"
          @click="save"
          icon="pi pi-save"
        />
      </div>
    </form>
  </div>
</template>

<script>
import FormInputText from '@/components/form/FormInputText.vue'
import { ref } from 'vue'
import Calendar from 'primevue/calendar'
import FileUpload from 'primevue/fileupload'
import Textarea from 'primevue/textarea'
import tenderApi from '@/api/tender'
import router from '@/router'
import Listbox from 'primevue/listbox'
import { useDictionaryStore } from '@/store/dictionary.store'
import { useAuthStore } from '@/store/auth.store'
import InputNumber from 'primevue/inputnumber'

export default {
  components: {
    FormInputText,
    Calendar,
    FileUpload,
    Textarea,
    Listbox,
    InputNumber,
  },
  setup() {
    // User
    const authStore = useAuthStore()
    const { user } = authStore

    const submitting = ref(false)
    const errors = ref([])
    const form = ref({
      date_beg: null,
      date_end: null,
      category_id: null,
      brand: null,
      options: {
        pack: {
          length: null,
          width: null,
          height: null,
        },
      },
      note: null,
      files: [],
      quantity: null,
    })

    // Categories
    const dictionaryStore = useDictionaryStore()
    const { tree } = dictionaryStore
    const currentCategories = ref([])
    const categoryPath = ref([null])
    const currentCategoryLabel = ref()

    const setCurrentCategories = (data, index, parentId = null) => {
      currentCategories.value[index] = data.filter(
        (category) => category.parent_id == parentId
      )
    }

    // Set categories
    setCurrentCategories(tree, 0)

    // Update current categories
    const updateCurrentCategories = (i) => {
      currentCategories.value = currentCategories.value.slice(0, i + 1)
      categoryPath.value = categoryPath.value.slice(0, i + 1)

      if (categoryPath.value[i].children.length > 0) {
        currentCategories.value[i + 1] = categoryPath.value[i].children
      }

      form.value.category_id = categoryPath.value[i].id

      // Category path label
      currentCategoryLabel.value = ''
      categoryPath.value.forEach((category, i) => {
        let arr = categoryPath.value.length - 1 == i ? '' : ' -> '
        currentCategoryLabel.value += category.name + arr
      })
    }

    // Upload files
    const onUpload = (e) => {
      form.value.files = e.files
    }

    // Save
    const save = () => {
      submitting.value = true
      errors.value = []

      let request

      if (form.value.id) {
        console.log('update')
      } else {
        request = tenderApi.addTender(form.value)
      }

      request
        .then((response) => {
          submitting.value = false
          router.push(`/tenders/${response.data.id}`)
        })
        .catch((error) => {
          errors.value = error.response.data.errors
          submitting.value = false
        })
    }

    return {
      form,
      errors,
      submitting,
      onUpload,
      save,
      currentCategories,
      categoryPath,
      updateCurrentCategories,
      currentCategoryLabel,
      user,
    }
  },
}
</script>
