<template>
  <Card>
    <template #content>
      <div
        class="mb-3 grid border rounded bg-slate-50 p-5 pb-0 content-end"
        style="height: 50vh"
      >
        <!-- Message -->
        <div
          v-for="(message, key) in messages"
          :key="key"
          class="flex gap-4 mb-5"
        >
          <div class="flex-none content-center">
            <Avatar
              :label="message.sender.name.charAt(0).toUpperCase()"
              size="large"
              shape="circle"
              class="avatar-green"
            />
          </div>
          <div
            class="flex-auto rounded-xl border p-5 bg-white"
            :class="{
              'bg-blue-50': tenderStore.tenderInfo.id == message.sender.id,
            }"
          >
            {{ message.text }}
          </div>
          <div class="flex-none pt-2">
            <span class="text-gray-500">{{ message.createdAt }}</span>
          </div>
        </div>
      </div>

      <!-- Send message -->
      <div class="flex flex-row gap-4">
        <div class="basis-11/12">
          <Textarea v-model="form.message" class="w-full" rows="1" />
        </div>
        <div class="basis-1/12">
          <Button
            type="button"
            :label="$t('send')"
            :loading="submitting"
            @click="send"
            icon="pi pi-send"
            class="w-full"
            :disabled="!canSend"
          />
        </div>
      </div>
    </template>
  </Card>
  <Toast position="bottom-right" />
</template>

<script>
import { ref, computed } from 'vue'
import Avatar from 'primevue/avatar'
import tenderApi from '@/api/tender'
import { useTenderStore } from '@/store/tender.store'
import { useAuthStore } from '@/store/auth.store'
import { useToast } from 'primevue/usetoast'
import i18n from '@/i18n'
import router from '@/router'

export default {
  components: { Avatar },
  setup() {
    // User
    const authStore = useAuthStore()
    const { user } = authStore

    // Toast
    const toast = useToast()
    // Tender
    const tenderStore = useTenderStore()
    if (!tenderStore.tenderInfo.canSeeDocs) {
      router.push('/tenders')
    }

    const submitting = ref(false)
    const form = ref({})

    const canSend = computed(() => {
      return form.value.message
    })

    // Get messages
    const messages = ref([])
    const getMessages = () => {
      tenderApi
        .getChatMessages(tenderStore.tenderInfo.id)
        .then((response) => {
          messages.value = response.data
        })
        .catch((error) => {
          toast.add({
            severity: 'error',
            summary: i18n.global.t('error'),
            detail: error.response.data.message,
            life: 5000,
          })
        })
    }
    getMessages()

    // Send message
    const send = () => {
      submitting.value = true
      tenderApi
        .sendChatMessage(tenderStore.tenderInfo.id, form.value)
        .then((response) => {
          submitting.value = false
          form.value.message = null
          toast.add({
            severity: 'success',
            detail: response.data.message,
            life: 3000,
          })
          getMessages()
        })
        .catch((error) => {
          submitting.value = false
          toast.add({
            severity: 'error',
            summary: i18n.global.t('error'),
            detail: error.response.data.message,
            life: 5000,
          })
        })
    }

    // Set messages seen
    setTimeout(() => {
      tenderApi
        .setMessagesSeen(tenderStore.tenderInfo.id)
        .then(() => {
          tenderStore.fetchTender(tenderStore.tenderInfo.id)
        })
        .catch((error) => {
          toast.add({
            severity: 'error',
            summary: i18n.global.t('error'),
            detail: error.response.data.message,
            life: 5000,
          })
        })
    }, 3000)

    return { messages, form, submitting, canSend, user, send, tenderStore }
  },
}
</script>

<style lang="scss" scoped>
.avatar-green {
  @apply bg-green-600 text-white;
}
</style>
