<template>
  <div>
    <Breadcrumb :home="home" :model="items" class="mb-6 -mt-6" />
    <h1 class="text-3xl font-bold mb-8">
      <span
        v-tooltip.top="$t('tenderStatus' + tenderStore.tenderStatus)"
        :class="`tender-status-${tenderStore.tenderStatus}`"
        >&nbsp;</span
      >
      {{ $t('tenderNum') }} {{ tenderId }}
    </h1>
    <TabMenu :model="tenderMenu" :exact="false" class="mb-5 -mt-5" />
    <router-view />
  </div>
</template>

<script>
import Breadcrumb from 'primevue/breadcrumb'
import TabMenu from 'primevue/tabmenu'
import { ref, defineComponent, onBeforeMount } from 'vue'
import i18n from '@/i18n'
import router from '@/router'
import { useTenderStore } from '@/store/tender.store'

export default defineComponent({
  components: {
    Breadcrumb,
    TabMenu,
  },
  setup() {
    // Tender
    const tenderId = router.currentRoute.value.params.id
    // Get tender info
    const tenderStore = useTenderStore()

    onBeforeMount(() => {
      tenderStore.fetchTender(tenderId).then(() => {
        buildMenu()
      })
    })

    // Tender menu
    const tenderMenu = ref([
      {
        label: i18n.global.t('info'),
        icon: 'pi pi-fw pi-info-circle',
        to: '/tenders/' + tenderId,
      },
    ])

    const buildMenu = () => {
      const newMessagesCount =
        tenderStore.tenderInfo.newMessagesCount > 0
          ? ` (${tenderStore.tenderInfo.newMessagesCount})`
          : ''
      // Chat & docs
      tenderMenu.value.push(
        // Docs
        {
          label: i18n.global.t('docs'),
          icon: 'pi pi-fw pi-file',
          to: `/tenders/${tenderId}/docs`,
          disabled: !tenderStore.tenderInfo.canSeeDocs,
        },
        // Chat
        {
          label: i18n.global.t('chat') + newMessagesCount,
          icon: 'pi pi-fw pi-comments',
          to: `/tenders/${tenderId}/chat`,
          disabled: !tenderStore.tenderInfo.canSeeDocs,
        }
      )
    }

    // Nav
    const home = ref({
      icon: 'pi pi-home',
      to: '/',
    })
    const items = ref([
      { label: i18n.global.t('nav.tenders'), to: '/tenders' },
      {
        label: i18n.global.t('tenderNum') + ' ' + tenderId,
        disabled: true,
      },
    ])

    return {
      home,
      items,
      tenderMenu,
      tenderId,
      tenderStore,
    }
  },
})
</script>
