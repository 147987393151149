<template>
  <Card>
    <template #title>
      {{ $t('addDesignRequestTitle') }}
    </template>
    <template #content>
      <DesignForm />
    </template>
  </Card>
</template>

<script>
import DesignForm from '@/components/design/DesignForm.vue'

export default {
  components: { DesignForm },
}
</script>
