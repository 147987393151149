<template>
  <form @submit.prevent="save">
    <div class="grid grid-cols-3 gap-x-8 mb-6">
      <div class="col-span-3">
        <FormInputText
          v-model="form.name"
          :id="`name`"
          :label="$t('companyName')"
          :errors="errors.name"
        />
      </div>
    </div>
    <div class="grid grid-cols-3 gap-x-8 mb-6">
      <div>
        <FormInputText
          v-model="form.identifier0"
          :id="`identifier0`"
          :label="$t('company.identifier0')"
          :errors="errors.identifier0"
        />
      </div>
      <div>
        <FormInputText
          v-model="form.identifier1"
          :id="`identifier1`"
          :label="$t('company.identifier1')"
          :errors="errors.identifier1"
        />
      </div>
      <div>
        <FormInputText
          v-model="form.identifier2"
          :id="`identifier2`"
          :label="$t('company.identifier2')"
          :errors="errors.identifier2"
        />
      </div>
    </div>
    <div class="grid grid-cols-3 gap-x-8 mb-6">
      <div>
        <FormInputText
          v-model="form.identifier3"
          :id="`identifier3`"
          :label="$t('company.identifier3')"
          :errors="errors.identifier3"
        />
      </div>
      <div>
        <FormInputText
          v-model="form.identifier4"
          :id="`identifier4`"
          :label="$t('company.identifier4')"
          :errors="errors.identifier4"
        />
      </div>
      <div></div>
    </div>
    <div class="grid grid-cols-3 gap-x-8 mb-6">
      <div>
        <FormInputText
          v-model="form.director"
          :id="`director`"
          :label="$t('director')"
          :errors="errors.director"
        />
      </div>
      <div>
        <FormInputText
          v-model="form.accountant"
          :id="`accountant`"
          :label="$t('accountant')"
          :errors="errors.accountant"
        />
      </div>
      <div>
        <FormInputText
          v-model="form.site"
          :id="`site`"
          :label="$t('website')"
          :errors="errors.site"
          :icon="`pi pi-globe`"
        />
      </div>
    </div>

    <!-- Addresses -->
    <h3 class="text-lg font-bold mb-3">{{ $t('addresses') }}</h3>
    <div v-if="!form.addresses.length">{{ $t('noAddresses') }}.</div>
    <div v-else>
      <div
        v-for="(address, i) in form.addresses.filter(
          (address) => !address.toDelete
        )"
        :key="i"
        class="mb-8"
      >
        <Button
          @click="removeAddress(i)"
          type="button"
          icon="pi pi-trash"
          class="p-button-outlined p-button-danger p-button-sm float-right"
        />
        <div class="mb-5">
          <span class="field-radiobutton mr-5">
            <RadioButton
              :inputId="`kind${i}1`"
              :name="`kind[${i}]`"
              :value="1"
              v-model="address.kind"
              class="align-middle mr-2"
            />
            <label :for="`kind${i}1`">{{ $t('juAddress') }}</label>
          </span>
          <span class="field-radiobutton mr-5">
            <RadioButton
              :inputId="`kind${i}2`"
              :name="`kind[${i}]`"
              :value="2"
              v-model="address.kind"
              class="align-middle mr-2"
            />
            <label :for="`kind${i}2`">{{ $t('factAddress') }}</label>
          </span>
          <span class="field-radiobutton mr-5">
            <RadioButton
              :inputId="`kind${i}3`"
              :name="`kind[${i}]`"
              :value="3"
              v-model="address.kind"
              class="align-middle mr-2"
            />
            <label :for="`kind${i}3`">{{ $t('postAddress') }}</label>
          </span>
          <div v-if="errors[`addresses${i}kind`]">
            <small
              v-for="(error, i) in errors[`addresses${i}kind`]"
              :key="i"
              class="p-error"
              >{{ error }}</small
            >
          </div>
        </div>
        <div class="grid grid-cols-12 gap-x-8 mb-6">
          <div class="col-span-2">
            <FormInputText
              v-model="address.postcode"
              :id="`address${i}postcode`"
              :label="$t('postCode')"
              :errors="errors[`addresses${i}postcode`]"
            />
          </div>
          <div class="col-span-10">
            <FormInputText
              v-model="address.address"
              :id="`address${i}address`"
              :label="$t('address')"
              :errors="errors[`addresses${i}address`]"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="mt-5 mb-8">
      <Button
        type="button"
        @click="addAddress"
        :label="$t('addAddress')"
        icon="pi pi-plus"
        class="p-button-outlined p-button-secondary p-button-sm"
      />
    </div>

    <!-- Requisites -->
    <h3 class="text-lg font-bold mb-3">{{ $t('requisites') }}</h3>
    <div v-if="!form.requisites.length">{{ $t('noRequisites') }}.</div>
    <div v-else>
      <div v-for="(req, i) in form.requisites" :key="i" class="mb-8">
        <Button
          @click="removeRequisites(i)"
          type="button"
          icon="pi pi-trash"
          class="p-button-outlined p-button-danger p-button-sm float-right mb-5"
        />
        <div class="mb-5">
          <FormInputText
            v-model="req.bank"
            :id="`req${i}bank`"
            :label="$t('bank')"
            :errors="errors[`requisites${i}bank`]"
          />
        </div>
        <div class="grid grid-cols-3 gap-x-8 mb-6">
          <div>
            <FormInputText
              v-model="req.account"
              :id="`req${i}account`"
              :label="$t('account')"
              :errors="errors[`requisites${i}account`]"
            />
          </div>
          <div>
            <FormInputText
              v-model="req.cor_account"
              :id="`req${i}cor_account`"
              :label="$t('cor_account')"
              :errors="errors[`requisites${i}cor_account`]"
            />
          </div>
          <div>
            <FormInputText
              v-model="req.bik"
              :id="`req${i}bik`"
              :label="$t('bik')"
              :errors="errors[`requisites${i}bik`]"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="mt-5 mb-8">
      <Button
        type="button"
        @click="addRequisites"
        :label="$t('addRequisites')"
        icon="pi pi-plus"
        class="p-button-outlined p-button-secondary p-button-sm"
      />
    </div>

    <!-- Save -->
    <div class="pt-2">
      <Button
        type="button"
        :label="$t('save')"
        :loading="submitting"
        @click="save"
        icon="pi pi-save"
      />
    </div>
  </form>
</template>

<script>
import { ref } from 'vue'
import FormInputText from '../form/FormInputText.vue'
import companyApi from '@/api/company'

export default {
  props: ['company'],
  components: { FormInputText },
  setup(props) {
    const submitting = ref(false)
    const errors = ref([])
    const form = ref(props.company)

    // Save
    const save = () => {
      submitting.value = true
      errors.value = []
      let request

      // Update company
      if (form.value.id) {
        request = companyApi.updateCompany(form.value.id, form.value)
      }
      // Add company
      else {
        request = companyApi.addCompany(form.value)
      }

      request
        .then((response) => {
          form.value = response.data
          submitting.value = false
        })
        .catch((error) => {
          errors.value = error.response.data.errors
          submitting.value = false
        })
    }

    // Add address
    const addAddress = () => {
      form.value.addresses.push({
        id: null,
        kind: null,
        address: null,
        postcode: null,
      })
    }

    // Remove address
    const removeAddress = (index) => {
      if (form.value.addresses[index].id) {
        form.value.addressesToDelete.push(form.value.addresses[index].id)
      }
      form.value.addresses = form.value.addresses.filter((address, i) => {
        return i !== index
      })
    }

    // Add requisites
    const addRequisites = () => {
      form.value.requisites.push({
        id: null,
        bank: null,
        account: null,
        cor_account: null,
        bik: null,
      })
    }

    // Remove requisites
    const removeRequisites = (index) => {
      if (form.value.requisites[index].id) {
        form.value.requisitesToDelete.push(form.value.requisites[index].id)
      }
      form.value.requisites = form.value.requisites.filter((req, i) => {
        return i !== index
      })
    }

    return {
      form,
      submitting,
      errors,
      save,
      addAddress,
      removeAddress,
      addRequisites,
      removeRequisites,
    }
  },
}
</script>
