<template>
  <Card>
    <template #title>
      {{ $t('addingComany') }}
    </template>
    <template #content>
      <CompanyForm :company="company" />
    </template>
  </Card>
</template>

<script>
import { ref } from 'vue'
import CompanyForm from '@/components/profile/CompanyForm.vue'

export default {
  components: { CompanyForm },
  setup() {
    const company = ref({
      name: null,
      identifier0: null,
      identifier1: null,
      identifier2: null,
      identifier3: null,
      identifier4: null,
      site: null,
      director: null,
      accountant: null,
      addresses: [],
      requisites: [],
    })

    return { company }
  },
}
</script>
