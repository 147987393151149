import api from './axios'
import { convertFormData } from '@/utils/helpers'

const tenderApi = {
  getTenders(query) {
    const q = query ? `?${query}` : ''
    console.log(q, query)
    return api.get('/tenders' + q)
  },
  // Add tender
  addTender(data) {
    return api.post('/tenders', convertFormData(data), {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  },
  // Update tender
  updateTender(id, data) {
    return api.postForm(`/tenders/${id}`, data)
  },
  // Get tender
  getTender(id) {
    return api.get(`/tenders/${id}`)
  },
  // Set tender status
  setStatus(id, statusData) {
    return api.post(`/tenders/${id}/set_status`, statusData)
  },
  // Delete
  delete(id) {
    return api.delete(`/tenders/${id}`)
  },
  // Request
  request(id, data) {
    return api.post(`/tenders/${id}/request`, data)
  },
  // Approve request
  approveRequest(id) {
    return api.post(`/tenders/approve_request/${id}`)
  },
  // Deny request
  denyRequest(id) {
    return api.post(`/tenders/deny_request/${id}`)
  },
  // Approve execute
  approveExecute(id) {
    return api.post(`/tenders/approve_execute/${id}`)
  },
  // My tenders
  getMyTenders(query = null) {
    return api.get('/tenders/my' + query)
  },
  // Search
  search(query) {
    return api.get('/tenders/search?' + query)
  },
  // Upload docs
  uploadDocs(id, data) {
    return api.post(`/tenders/${id}/upload_docs`, convertFormData(data), {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  },
  // Get tender docs
  getTenderDocs(id) {
    return api.get(`/tenders/${id}/docs`)
  },
  // Send chat message
  sendChatMessage(id, data) {
    return api.post(`/tenders/${id}/send_chat_message`, data)
  },
  // Get chat messages
  getChatMessages(id) {
    return api.get(`/tenders/${id}/get_chat_messages`)
  },
  // Set messages seen
  setMessagesSeen(id) {
    return api.post(`/tenders/${id}/set_messages_seen`)
  },
  // Add bid
  addBid(id, data) {
    return api.post(`/tenders/${id}/bid`, data)
  },
  // Approve bid
  approveBid(id) {
    return api.post(`tenders/approve_bid/${id}`)
  },
}

export default tenderApi
