<template>
  <Card>
    <template #title>
      {{ $t('myCompanies') }}
      <router-link to="/profile/companies/add">
        <Button
          :label="$t('addCompany')"
          icon="pi pi-plus"
          class="p-button-sm float-right"
        />
      </router-link>
    </template>
    <template #content>
      <DataTable
        :value="companies"
        v-model:selection="defaultCompany"
        selectionMode="single"
        @row-select="updateDefault"
      >
        <Column selectionMode="single"></Column>
        <Column field="name" :header="$t('companyName')"></Column>
        <Column
          field="identifier0"
          :header="$t('company.identifier0')"
        ></Column>
        <Column
          field="identifier1"
          :header="$t('company.identifier1')"
        ></Column>
        <Column
          field="identifier2"
          :header="$t('company.identifier2')"
        ></Column>
        <Column
          field="identifier3"
          :header="$t('company.identifier3')"
        ></Column>
        <Column
          field="identifier4"
          :header="$t('company.identifier4')"
        ></Column>
        <Column bodyStyle="text-align: right;">
          <template #body="{ data }">
            <router-link :to="`/profile/companies/${data.id}`">
              <Button
                type="button"
                icon="pi pi-cog"
                class="p-button-outlined p-button-secondary p-button-sm bg-white"
              ></Button>
            </router-link>
          </template>
        </Column>
      </DataTable>
    </template>
  </Card>
</template>

<script>
import { ref, onMounted } from 'vue'
import companyApi from '@/api/company'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import { useAuthStore } from '@/store/auth.store'

export default {
  components: { DataTable, Column },
  setup() {
    const companies = ref([])
    const loading = ref(true)
    const defaultCompany = ref()
    const store = useAuthStore()
    // const { user } = store

    // Get user's companies
    onMounted(() => {
      companyApi.getUserCompanies().then((response) => {
        companies.value = response.data
        loading.value = false

        // Set default company
        companies.value.forEach((company) => {
          if (company.is_default) {
            defaultCompany.value = company
          }
        })
      })
    })

    // Set default
    const updateDefault = (event) => {
      companyApi.setDefault(event.data.id).then((response) => {
        store.setDefaultCompany(response.data)
      })
    }

    return { companies, loading, defaultCompany, updateDefault }
  },
}
</script>
