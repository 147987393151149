<template>
  <div class="text-center p-20">
    <img
      src="@/assets/img/under-construction.png"
      alt=""
      class="under-construction-pic"
    />
    <div class="mt-5">{{ $t('underConstuction') }}</div>
  </div>
</template>

<style lang="scss" scoped>
.under-construction-pic {
  width: 150px;
  display: inline;
}
</style>
