<template>
  <div>
    <Card>
      <template #title>{{ $t('nav.categories') }}</template>
      <template #content>
        <UnderConstruction />
      </template>
    </Card>
  </div>
</template>

<script>
import UnderConstruction from '@/components/blocks/UnderConstruction.vue'

export default {
  components: { UnderConstruction },
}
</script>
