<template>
  <div>
    <Card>
      <template #title>{{ $t('nav.settings') }}</template>
      <template #content>
        <!-- <UnderConstruction /> -->
        <div class="mb-5">
          <Checkbox
            inputId="categories"
            v-model="notifyTenders"
            :binary="true"
            class="align-middle"
          />
          <label for="categories" class="ml-2">{{ $t('tender_notify') }}</label>
        </div>
        <div v-if="notifyTenders">
          <div class="mb-3">
            Выберите категории, по которым вам будут приходить уведомления о
            новых тендерах:
          </div>
          <Tree
            v-model:selectionKeys="selectedKey"
            :value="preparedTree"
            selectionMode="checkbox"
            class="w-full md:w-30rem mb-5"
          ></Tree>
          <Button
            type="button"
            :label="$t('save')"
            :loading="submitting"
            @click="save"
            icon="pi pi-save"
          />
        </div>
      </template>
    </Card>
  </div>
</template>

<script>
// import UnderConstruction from '@/components/blocks/UnderConstruction.vue'
import Tree from 'primevue/tree'
import { ref, defineComponent } from 'vue'
import { useDictionaryStore } from '@/store/dictionary.store'
import userSettingsApi from '@/api/userSettings'
import { useToast } from 'primevue/usetoast'

export default defineComponent({
  components: { Tree },
  setup() {
    const nodes = ref(null)
    const selectedKey = ref(null)
    const notifyTenders = ref(false)
    const submitting = ref(false)
    const toast = useToast()

    // Categories
    const dictionaryStore = useDictionaryStore()
    const { preparedTree, preparedtreeIds } = dictionaryStore

    // Get settings
    userSettingsApi.getSettings().then((response) => {
      selectedKey.value = JSON.parse(response.data.options)
      if (response.data.notify_categories) {
        notifyTenders.value = true
      }
    })

    // Save
    const save = () => {
      submitting.value = true
      let categoryIds = []

      Object.keys(selectedKey.value).forEach((key) => {
        if (selectedKey.value[key].checked === true) {
          categoryIds.push(preparedtreeIds[key])
        }
      })

      userSettingsApi
        .setSettings({
          categories: categoryIds,
          options: JSON.stringify(selectedKey.value),
        })
        .then((response) => {
          submitting.value = false
          toast.add({
            severity: 'success',
            detail: response.data.message,
            life: 3000,
          })
        })
    }

    return { nodes, selectedKey, notifyTenders, preparedTree, submitting, save }
  },
})
</script>
