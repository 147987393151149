import api from './axios'

const supportApi = {
  getTickets() {
    return api.get('/support/tickets')
  },
  addTicket(data) {
    return api.post('/support/tickets', data)
  },
  getTicket(id) {
    return api.get(`/support/tickets/${id}`)
  },
  // Send chat message
  sendChatMessage(id, data) {
    return api.post(`/support/tickets/${id}/send_message`, data)
  },
  // Get chat messages
  getChatMessages(id) {
    return api.get(`/support/tickets/${id}/get_messages`)
  },
  // Set messages seen
  setMessagesSeen(id) {
    return api.post(`/support/tickets/${id}/set_messages_seen`)
  },
}

export default supportApi
