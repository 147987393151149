<template>
  <div>
    <Card>
      <template #title>
        {{ $t('nav.design') }}
        <router-link to="/design/add">
          <Button
            :label="$t('addDesignRequest')"
            icon="pi pi-plus"
            class="p-button-sm float-right"
          />
        </router-link>
      </template>
      <template #content>
        <template v-if="requests.length > 0">
          <Panel v-for="request in requests" :key="request.id" class="mb-5">
            <template #header>
              <div class="flex align-items-center gap-2">
                <router-link :to="`/design/${request.id}`" class="font-bold">
                  {{ $t('request') }} {{ request.id }}
                </router-link>
              </div>
            </template>
            <div class="mb-5">
              <p>{{ request.text }}</p>
            </div>
            <div class="grid grid-cols-2">
              <div>
                <InlineMessage v-if="request.status == 0" severity="info">{{
                  $t('designRequestNew')
                }}</InlineMessage>
                <InlineMessage v-if="request.status == 1" severity="success">{{
                  $t('designRequestActive')
                }}</InlineMessage>
                <InlineMessage
                  v-if="request.status == 2"
                  severity="error"
                  class="bg-red-50"
                  >{{ $t('designRequestFinished') }}</InlineMessage
                >
                <InlineMessage v-if="request.status == 3" severity="success">{{
                  $t('designRequestCancelled')
                }}</InlineMessage>
              </div>
              <div class="text-right pt-4">
                <span class="p-text-secondary">{{ request.createdAt }}</span>
              </div>
            </div>
          </Panel>
        </template>
        <div v-else>
          <b>У вас нет активных заявок на разработку дизайна.</b>
        </div>
      </template>
    </Card>
  </div>
</template>

<script>
import Panel from 'primevue/panel'
import designApi from '@/api/design'
import { ref } from 'vue'

export default {
  components: { Panel },
  setup() {
    const requests = ref([])

    const getRequests = () => {
      designApi.getDesignRequests().then((response) => {
        requests.value = response.data
      })
    }

    getRequests()

    return { requests }
  },
}
</script>
