import api from './axios'

const authApi = {
  // Login
  login(username, password, rememberMe) {
    return api.post('/auth/login', {
      username: username,
      password: password,
      rememberMe: rememberMe,
    })
  },
  // Get authorized user info
  getAuthUser() {
    return api.get('/auth/user')
  },
  // Registration
  register(data) {
    let formData = { ...data }
    // Cast
    formData.type = parseInt(formData.type)
    formData.form_type = parseInt(formData.form_type)

    return api.post('/auth/registration', formData)
  },
  // Update profile
  updateUser(data) {
    return api.post('/auth/user', data)
  },
}

export default authApi
