import i18n from '../i18n'

const menuItems = [
  {
    label: i18n.global.t('nav.tenders'),
    roles: ['ROLE_USER'],
    items: [
      {
        label: i18n.global.t('nav.activeTenders'),
        icon: 'pi pi-bolt',
        to: '/tenders',
        roles: ['ROLE_USER'],
      },
      {
        label: i18n.global.t('nav.myTenders'),
        icon: 'pi pi-inbox',
        to: '/tenders/my',
        roles: ['ROLE_USER'],
      },
      // {
      //   label: i18n.global.t('nav.requests'),
      //   icon: 'pi pi-send',
      //   to: '/tenders/requests',
      //   roles: ['ROLE_USER'],
      // },
    ],
  },
  { separator: true, roles: ['ROLE_USER'] },
  {
    label: i18n.global.t('nav.design'),
    icon: 'pi pi-image',
    to: '/design',
    roles: ['ROLE_USER'],
  },
  {
    label: i18n.global.t('nav.help'),
    icon: 'pi pi-question-circle',
    to: '/support',
    roles: ['ROLE_USER'],
  },
  {
    label: i18n.global.t('nav.settings'),
    icon: 'pi pi-cog',
    to: '/settings',
    roles: ['ROLE_USER'],
  },
  {
    label: i18n.global.t('nav.admin'),
    roles: ['ROLE_ADMIN'],
    items: [
      {
        label: i18n.global.t('nav.users'),
        icon: 'pi pi-user',
        to: '/admin/users',
        roles: ['ROLE_ADMIN'],
      },
      {
        label: i18n.global.t('nav.regions'),
        icon: 'pi pi-globe',
        to: '/admin/regions',
        roles: ['ROLE_ADMIN'],
      },
      {
        label: i18n.global.t('nav.categories'),
        icon: 'pi pi-book',
        to: '/admin/categories',
        roles: ['ROLE_ADMIN'],
      },
      {
        label: i18n.global.t('support'),
        icon: 'pi pi-question-circle',
        to: '/admin/support',
        roles: ['ROLE_ADMIN'],
      },
    ],
  },
]

export default menuItems
