import { defineStore } from 'pinia'
import tenderApi from '@/api/tender'
import { getCategoryPath } from '@/utils/helpers'

export const useTenderStore = defineStore('tender', {
  state: () => ({
    tender: {
      owner: {},
      company: {},
      executor: {},
      options: {
        pack: {
          legth: null,
          width: null,
          height: null,
        },
      },
      files: [],
      category_path: [],
      requests: [],
    },
  }),
  actions: {
    // Get tender
    async fetchTender(id) {
      const response = await tenderApi.getTender(id)
      this.setTender(response.data)
    },
    // Set tender
    setTender(data) {
      this.tender = data
    },
  },
  getters: {
    tenderStatus(state) {
      return state.tender.status
    },
    categoryPath(state) {
      return getCategoryPath(state.tender.category)
    },
    hasPack(state) {
      return (
        state.tender.options.pack['length'] > 0 &&
        state.tender.options.pack['width'] > 0 &&
        state.tender.options.pack['height'] > 0
      )
    },
    tenderInfo(state) {
      return state.tender
    },
  },
})
