<template>
  <h1 class="font-bold text-2xl mb-5">{{ $t('notifications') }}</h1>
  <template v-if="notifications.length">
    <Card
      v-for="notification in notifications"
      :key="notification.id"
      class="mb-5"
    >
      <template #content>
        <div class="grid grid-cols-12">
          <div class="col-span-10">
            <router-link :to="notification.link" v-if="notification.link">
              {{ notification.text }}
            </router-link>
          </div>
          <div class="col-span-2 text-right text-gray-500">
            {{ notification.createdAt }}
          </div>
        </div>
      </template>
    </Card>
  </template>
  <Card v-else class="mb-5">
    <template #content>
      {{ $t('noNotifications') }}
    </template>
  </Card>
</template>

<script>
import notificationsApi from '@/api/notifications'
import { ref } from 'vue'

export default {
  setup() {
    const notifications = ref([])

    // Get notifications
    notificationsApi.getNotifications().then((response) => {
      notifications.value = response.data
    })

    return { notifications }
  },
}
</script>
